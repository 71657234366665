import React from 'react'
import { Alert, AlertColor } from '@mui/material'
import { Snackbar } from '@mui/material'

export interface ToastProps {
  text: string
  isOpen: boolean
  severity: AlertColor
  onClose: () => void
}

const Toast = ({ text, isOpen, severity, onClose }: ToastProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={onClose}
      onClick={onClose}
    >
      <Alert severity={severity} elevation={6} variant="filled">
        {text}
      </Alert>
    </Snackbar>
  )
}

export default Toast
