import { env } from "src/env.mjs";

export const Endpoints = {
  securityCheck: `${env.NEXT_PUBLIC_SIMO_API_URL}/eq/auth`,
  topEquipmentCheck: `${env.NEXT_PUBLIC_SIMO_API_URL}/eq/topEquipment`,
  customerExists: `${env.NEXT_PUBLIC_SIMO_API_URL}/eq/customerExists`,
  zipCodeCheck: `${env.NEXT_PUBLIC_SIMO_API_URL}/eq/customerZipCode`,
  ipStack: `${env.NEXT_PUBLIC_SIMO_API_URL}/ipstack`,
  partnerByCountry: `${env.NEXT_PUBLIC_SIMO_API_URL}/view/partner/byCountry`,
  submitEmData: `${env.NEXT_PUBLIC_SIMO_API_URL}/eq/submit`,
} as const
