import styles from './index.module.css'

interface Props {
  text: string
  href: string
}

const RegularLink = ({ text, href }: Props) => (
  <a className={styles['main']} href={href} target="_blank" rel="noreferrer">
    {text}
  </a>
)

export default RegularLink
