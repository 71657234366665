import '../styles/globals.css'
import styles from '../src/components/HelpPage/Home/index.module.css'
import React, { useEffect } from 'react'
import Footer from '../src/components/Footer'
import PreHeader from '../src/components/PreHeader'
import {
  FullPageLoaderContextProvider,
  useLoaderContext,
} from '../src/lib/contexts/FullPageLoaderContext'
import { ToastContextProvider } from '../src/lib/contexts/ToastContext'
import { EmContextProvider } from '../src/lib/contexts/EmContext'
import { useRouter } from 'next/router'
import { theme } from '../src/constants/Theme'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { IpStackContextProvider } from 'src/lib/contexts/IpStackContext'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: any) {
  const { setIsLoading } = useLoaderContext()
  const router = useRouter()

  useEffect(() => {
    const handleStart = () => setIsLoading(true)
    const handleStop = () => setIsLoading(false)

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  return (
    <FullPageLoaderContextProvider>
      <ToastContextProvider>
        <EmContextProvider>
          <IpStackContextProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                  <div className={styles['container']}>
                    <div className={styles['grey']} />
                    <PreHeader />
                    <div className={styles['grey']} />
                    <div />
                    <div className={styles['body']}>
                      <Component {...pageProps} />
                    </div>
                    <div />
                    <div className={styles['blue']} />
                    <div className={styles['footer']}>
                      <Footer />
                    </div>
                    <div className={styles['blue']} />
                  </div>
                </QueryClientProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </IpStackContextProvider>
        </EmContextProvider>
      </ToastContextProvider>
    </FullPageLoaderContextProvider>
  )
}

export default MyApp
