import HttpStatus from '../constants/HttpStatus'
import FetchError from '../lib/errors/FetchError'
import { ValueOf } from '../types'
// @ts-ignore
import fetch from 'node-fetch'

export interface FetchWrapperOpts extends RequestInit {
  url: string
  method: 'post' | 'get'
  data?: Record<string, any>
  params?: Record<string, any>
}

export const postJSON = async <T>({
  url,
  method,
  data,
  ...rest
}: FetchWrapperOpts): Promise<T> => {
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    ...rest,
  })

  if (res.status >= 400) {
    throw new FetchError({
      status: res.status as ValueOf<typeof HttpStatus>,
      statusText: res.statusText,
    })
  }

  const body = await res.json()
  return body
}

export const fetchWrapper = async <T>(opts: FetchWrapperOpts): Promise<T> => {
  if (opts.method === 'get') {
    const params = opts.params ? `?${new URLSearchParams(opts.params)}` : ''
    const res = await fetch(`${opts.url}${params}`, opts)

    if (res.status >= 400) {
      throw new FetchError({
        status: res.status as ValueOf<typeof HttpStatus>,
        statusText: res.statusText,
      })
    }
    const contentType = res.headers.get('content-type')
    if (contentType?.includes('application/json')) return res.json()
    if (contentType?.includes('text/plain')) return res.text() as any

    throw new Error(`Unsupported content type ${contentType}`)
  }

  const res = await postJSON<T>(opts)
  return res
}
