import React, { createContext, useContext, useState } from 'react'
import Toast, { ToastProps } from '../../components/Toast'

interface ToastContext {
  showToast: (opts: Pick<ToastProps, 'text' | 'severity'>) => void
}

type ToastState = Pick<ToastProps, 'text' | 'severity' | 'isOpen'>

const ToastContext = createContext<ToastContext>({ showToast: () => {} })

export const ToastContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, setToastState] = useState<ToastState>({
    text: '',
    isOpen: false,
    severity: 'info',
  })
  return (
    <ToastContext.Provider
      value={{
        showToast: ({ severity, text }) =>
          setToastState({ text, severity, isOpen: true }),
      }}
    >
      <Toast
        isOpen={state.isOpen}
        text={state.text}
        severity={state.severity}
        onClose={() => setToastState({ ...state, isOpen: false })}
      />
      {children}
    </ToastContext.Provider>
  )
}

export const useToastContext = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('context must be used within a Provider')
  }
  return context
}
