import EN from "./en";

const ES = {
  en: "English",
  de: "Deutsch",
  it: "Italiano",
  fr: "Français",
  es: "Español",
  language: "Español",
  help: "Ayuda",
  newHere: "¿Eres nuevo?",
  createAccount: "Crear cuenta",
  forgotPassword: "¿Olvidó su contraseña?",
  rememberMe: "Mantener la sesión",
  siroBlue: "Siroforce Mobile",
  signIn: "ENTRAR",
  faq: "Preguntas frequentes/Feedback/Soporte",
  mobileOverview: "Resumen Siroforce Mobile",
  siroforceMobile: "Acceso a Siroforce Mobile",
  deviceRegistration: "Registro de dispositivos",
  ticketing: "Solicitud de servicio / Ticket",
  preventiveMaintenance: "Mantenimiento preventivo",
  marketAction: "Market action",
  monitoring: "Evaluación e informes",
  helpcenter: "Centro de ayuda",
  welcome: "Bienvenido a Siroforce Mobile",
  access: "Su acceso al soporte tecnico de Dentsply Sirona",
  email: "Correo electrónico",
  password: "Contraseña",
  rightsReserved: "© 2024 Dentsply Sirona. Todos derechos reservados",
  privacy: "Política de privacidad",
  termsOfUse: "Condiciones de uso",
  imprint: "Aviso legal",
  commercialArea: "Área de distribuidores",
  compliance: "Política de Ética y Compliance",
  inc: "Dentsply Sirona Inc",
  instructions: "Instrucciones de uso",
  register: "Registrar",
  emailFieldLabel: "Correo electrónico (log in)",
  provideValidEmail: "Introducir una dirección de correo electrónico válida",
  provideValidPassword: "Tener en cuenta el formato correcto de la contraseña",
  passwordsDoNotMatch: "Las contraseñas no coinciden",
  fieldRequired: "Rellenar los campos obligatorios",
  firstName: "Nombre",
  lastName: "Apellido(s)",
  phone: "Teléfono",
  noPartnersFound: "No se ha encontrado ningún distribuidor",
  zipCode: "Código postal",
  chooseState: "País/Región",
  requiredPartner: "Hay que seleccionar un distribuidor",
  myRole: "Mi función",
  technician: "Técnico de servicio",
  sales: "Ventas",
  chooseCountry: "Seleccionar su país",
  passwordRepeat: "Repetir contraseña",
  emailTaken: "Correo electrónico ya registrado",
  signUp: "Registrar",
  error: "Error",
  registeredInClassic:
    "Ya está registrado en Siroforce Mobile. Por favor, conéctese.",
  registeredInEKP:
    "Ya está registrado en Siroforce Mobile. Por favor, conéctese.",
  close: "Cerrar",
  confirm: "Confirmar",
  none: "Vacío",
  registrationSuccess:
    "Gracias por registrarse. Si no recibe un correo electrónico de confirmación, compruebe su carpeta de correo no deseado. Sólo cuando su dirección de correo electrónico haya sido validada, se podrá completar el registro.",
  registrationReceived: "Solicitud de registro recibida",
  registrationFailed: "Registro fallido. Por favor, inténtelo más tarde.",
  youAre: "¿Es usted….?",
  endCustomer: "Cliente final",
  technicianSales: "Técnico/Distribuidor",
  thirdParty: "Terceros/Prestadores",
  check: "Enviar",
  alreadyRegisteredEKPStart:
    "Ya existe un usuario con su dirección de correo electrónico",
  here: "aqui",
  notRegisteredInEKP: "Todavía no hay ningún usuario. Por favor, regístrese.",
  alreadyExistingInTechnicianButEKP:
    "Ya existe una cuenta para su dirección de correo electrónico en el portal del técnico.",
  wantToTransferToEKP:
    "No obstante, si desea que su cuenta se traslade al portal de reclamaciones, envíe un correo electrónico a %s",
  alreadyRegisteredInClassic:
    "Ya existe un usuario con su dirección de correo electrónico.",
  notRegisteredInClassic:
    "Todavía no hay ningún usuario. Por favor, regístrese.",
  alreadyExistingInEKPButTechnician:
    "Ya existe una cuenta para su dirección de correo electrónico en el portal de reclamaciones.",
  wantToTransferToClassic:
    "No obstante, si desea que su cuenta se traslade al portal del técnico, envíe un correo electrónico a %s",
  confirmHeading: "Muchas gracias por confirmar su correo electrónico.",
  confirm1Body:
    "Los distribuidores de Dentsply Sirona suelen recibir un correo electrónico en pocos minutos con la activación de Siroforce Mobile. Si no recibe ",
  confirm2Body:
    "Su cuenta ha sido activada. Ahora puede conectarse directamente con Siroforce Mobile",
  loginSiroforce: "Login Siroforce Mobile",
  successfullLogin: "EConectado",
  errorLoggingIn: "Error al iniciar sesión",
  provideValidQueryParams: "Entrar parámetros de consulta válidos",
  verifyAssetInformation: "Confirmar información del equipo",
  assetFound:
    "Confirmar la siguiente información del equipo en forma de número de serie y referencia. Encontrará esta información en la placa de características situada en la parte posterior de su equipo.",
  verifyAssetFromLabel:
    "Verificar la información de los activos en la etiqueta",
  materialNumber: "Referencia",
  serialNumber: "Número de serie",
  pleaseConfirm: "Confirmar",
  false: "Cambiar",
  unexpectedError: "Error inesperado. Intentar de nuevo.",
  invalidToken:
    "Error. Comprobar si su token es válido y no ha caducado e intentar de nuevo.",
  assetNotFound:
    "No se ha podido encontrar su equipo. Por favor, seleccione manualmente.",
  yourDevice: "Introducir información del equipo",
  enterMaterialAndSerial:
    "Introducir la información del equipo en forma de número de serie y referencia. Encontrará esta información en la placa de características situada en la parte posterior de su equipo.",
  next: "Continuar",
  enterPostalCode: "Introducir su código postal",
  authorizeByEnteringPostalCode:
    "Hemos encontrado un registro existente en su equipo. Por favor, autentificarlo utilizando el código postal de su consulta para comprobar la exactitud del registro existente.",
  postalCode: "código postal",
  customerNotFound:
    "No se ha podido encontrar al cliente. Por favor, seleccionar manualmente.",
  checkContactInformation: "Confirmar registro",
  checkRelatedMasterData:
    "Por favor, confirmar el registro existente del equipo con Dentsply Sirona o hacer un cambio en caso de error.",
  correction: "Cambiar",
  customerData: "Su consultorio",
  enterCustomerData:
    "Parece que su equipo aún no está registrado en Dentsply Sirona. Para inscribirse, rellenar el siguiente formulario con los datos de su consulta.",
  name: "Nombre del consultorio",
  street: "Calle",
  city: "Ciudad",
  houseNumber: "Número",
  country: "País",
  state: "País/Región",
  allowAddressOptimization: "¿Podemos optimizar los datos de su dirección?",
  reject: "No",
  sent: "Lo que ha ingresado.",
  suggested: "Nuestro propuesta",
  selectDealer: "Su distibuidor",
  selectDealerWhichMaintainsDevice:
    "Por favor, seleccionar su distribuidor responsable del servicio técnico de su equipo.",
  foundTopEquipment: "Equipo identificado con éxito.",
  credentialsWentWrong:
    "Algo ha ido mal con su autentificación. Por favor, utilizar su url con token para acceder.",
  submitSuccess: "Muchas gracias, Su registro se ha completado con éxito.",
  confirmDealer: "Confirmar distribuidor",
  checkRelatedDealer:
    "Por favor, confirmar su distribuidor o hacer un cambio en caso de error.",
  identifyDevice: "Identificación del tipo de equipo",
  pleasePickDevice:
    "Se necesita información adicional sobre su equipo para poder registrarlo. Por favor, seleccionar el tipo de equipo aplicable a su unidad.",
  select: "Seleccionar",
  deviceHasToBeSelected: "Hay que seleccionar un equipo.",
  dealerUnknown: "Distribuidor desconocido",
  addMissingDealer: "Añadir distribuidor que falta",
  wrongPhoneFormat: "Formato teléfono inválido",
  wrongZipFormat: "Formato código postal inválido",
  welcomeToDentsply: " ¡Bienvenido a Dentsply Sirona!",
  welcomeImageText:
    "Trabajamos con usted para desarrollar soluciones y servicios integrados, inteligentes y centrados en el cliente para mejorar los resultados del tratamiento y conseguir una sonrisa sana. Por favor, completar el registro de su equipo en los siguientes pasos para que podamos ofrecerle la mejor asistencia en el futuro.",
  cerecPrimeScan: "CEREC Primescan",
  cerecOmniScan: "CEREC Omnicam",
  clinic: "Consultorio",
  serviceCenter: "Distribuidor",
  deviceSuccessfullyConnected:
    "Su equipo está ahora conectado a la nube, lo que nos ayuda a mejorar continuamente su experiencia de cliente con Dentsply Sirona.",
  deviceSuccessfullyConnected2:
    "Puede finalizar la sesión cerrando la ventana del navegador.",
  continue: "Continuar",
  authorize: "Autorizar",
  makeCorrections:
    "Por favor, corrigir la información incorrecta de su consultorio haciendo clic en la línea correspondiente.",
  postalCodeFail:
    "La autorización a través del código postal ha fallado. Estaremos encantados de corregir su información si nos proporciona los datos de su consulta.",
  oops: "Uy",
  somethingWentWrong: "Algo salió mal",
  sessionTimedOut:
    'Lamentablemente, la validez de su sesión ha expirado. Por favor, reiniciar el proceso de registro haciendo clic en el enlace "Comprobación del registro del equipo en línea" en la aplicación Dentsply Sirona System Monitor. Ahora puede cerrar esta ventana para ir automáticamente al Monitor del Sistema.',
  yes: "Sí",
  welcomeDentsplyProductService:
    "Bienvenido al Servicio del producto Dentsply Sirona",
  authorizedTechnicianSales:
    "Distribuidor autorizado <br> Empleado Dentsply Sirona",
  egDentist: "por ejemplo dentista, laboratorio, clinica",
  egITService: "por ejemplo Proveedor de servicios TI",
  yourEmailAddress: "Su dirección correo electrónico",
  redirectedToTechnicianPortal: "Será redirigido al portal del técnico.",
  registerHere: "Hacer clic aquí para registrarse.",
  redirectedToCustomerPortal: "Será redirigido al portal de reclamaciones.",
  asThirdPartyPleaseCall:
    "Como proveedor de terceros, por favor ponerse en contacto con nosotros por teléfono.",
  accessToTheDentsply:
    "El acceso a los portales de servicio de Dentsply Sirona está reservado exclusivamente a clientes finales, distribuidores autorizados y empleados de Dentsply Sirona.",
  pleaseContact:
    "Por favor, dirigirse al distribuidor especializado de Dentsply Sirona o ponerse en contacto con el Servicio de Productos de Dentsply Sirona por teléfono.",
  pleaseCallUs: "Por favor, llámenos.",
  findPhoneNumbers: "Se encuentran nuestros números de teléfono aquí:",
  iAm: "Soy...",
  loginTechnicianPortal: "Acceso para técnico/empleado DS",
  loginComplaintPortal: "Acceso para cliente final",
  notOfficialDealerDomain:
    "Su dominio de correo electrónico no está registrado actualmente como dominio oficial del distribuidor. La verificación de su solicitud de registro debe hacerse manualmente. Por favor, comprenda que la tramitación puede tardar unos días.",
  open: "Registro",
  filter: "Filtro (nombre, ciudad, código postal)",
  technicianPortal: "Portal del técnico",
  confirmErrorBody: "Comprobación del correo electrónico fallida",
  maintenanceHeading: "Modo de mantenimiento",
  maintenanceBody:
    "Actualmente se están realizando trabajos de mantenimiento para Siroforce. Volveremos pronto.",
  feedbackHeader:
    "Gracias por tomarse la molestia de hacernos llegar sus comentarios. Esto nos ayudará a mejorar este sitio para todos.",
  feedBackHint: "Este formulario de comentarios está destinado a informar sobre errores o elementos/temas faltantes en este sitio de soporte. El equipo web no puede proporcionar servicio técnico ni reenviar solicitudes técnicas a un equipo de servicio en un país lejano. Por favor, póngase en contacto con su distribuidor o equipo de servicio local para cualquier pregunta sobre el producto.",
  feedBackThankYou: "¡Gracias por su comprensión!",
  feedbackTitle: "Comentarios",
  feedbackName: "Nombre",
  feedbackSubmit: "Enviar",
  feedbackEmail: "Correo electrónico",
  feedbackDetails: "Su opinión",
  feedbackSubmitSuccess: "Gracias por sus comentarios.",
  feedbackSubmitError:
    "No se ha podido enviar su comentario. Vuelva a intentarlo más tarde.",
  urlCopiedToClipboard: "La URL se ha copiado en el portapapeles",

  // translate from here
  cancel: "Cancelar",
  createDate: "Fecha de creación",
  customer: "Cliente",
  description: "Descripción",
  device: "Dispositivo",
  dropFilesHere: "Almacenar archivos aquí",
  dropFilesOrSelect: "Almacenar o seleccionar archivos aquí",
  fileTooLarge:
    "El archivo es demasiado grande. Tamaño máximo del archivo 5 MB",
  invalidMaterialNumber: "Número de material no válido",
  localRequestStatusAll: "Todos",
  localRequestStatusClosed: "Completado",
  localRequestStatusInProgress: "En curso",
  localRequestStatusOpen: "Abrir",
  localRequestStatusPending: "Destacado",
  messageSendError: "No se ha podido enviar el mensaje",
  noteSendError: "No se ha podido enviar el mensaje",
  only5FilesAllowed: "Sólo se permiten 5 archivos",
  pleaseTypeYourMessage: "Escriba su mensaje",
  request: "Solicitud de servicio",
  requestStatusAdditionProvided: "En curso",
  requestStatusCancelled: "Abortado",
  requestStatusCompleted: "Completado",
  requestStatusCustomerAction: "Esperando su respuesta",
  requestStatusForwarded: "En curso",
  requestStatusInProgress: "En curso",
  requestStatusSolutionProvided: "Solución aportada",
  selectRole: "Seleccionar función",
  status: "Estado",
  uploadAttachments: "Cargar archivos adjuntos",
  PleaseContactYourSupportTeam:
    "Póngase en contacto con nuestro servicio de asistencia para aplicaciones",
  ErrorCode: "Código de error: ",
  pushNotification: "Notificaciones de push",
  INIT: "Iniciado",
  AWDE: "En espera de dispositivo",
  REPA: "Reparación finalizada",
  CANC: "Anulado",
  INSP: "Inspección",
  AWCA: "A la espera de la aprobación del cliente",
  REST: "Reparación iniciada",
  REDE: "Reparación denegada",
  CLOS: "Proceso finalizado",
  REAP: "Reparación aprobada",
  REPE: "A la espera de la aprobación del cliente",
};

const withDefaults = { ...EN, ...ES };

export default withDefaults;
