import React, { createContext, useContext, useEffect, useState } from 'react'
import { getIpStack, IpStack } from '../../api/Register'
import CanadaStates from '../../constants/CanadaStates'
import { CountryType, Countries } from '../../constants/Countries'
import USStates from '../../constants/USStates'
import { CountryState } from '../../types'
import useI18n from '../hooks/useI18n'

interface IpStackContext {
  ipStackCountry?: CountryType
  ipStackState?: CountryState
  ipStack?: IpStack
}

const IpStackContext = createContext<IpStackContext>({
  ipStack: undefined,
  ipStackState: undefined,
  ipStackCountry: undefined,
})

export const IpStackContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [ipStack, setIpStack] = useState<IpStack | undefined>()
  const { changeLanguage } = useI18n()

  useEffect(() => {
    getIpStack()
      .then((ipStack) => {
        setIpStack(ipStack)
        changeLanguage(ipStack.location.languages[0]?.code || 'en')
      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])
  return (
    <IpStackContext.Provider
      value={{
        ipStack: ipStack,
        ipStackCountry: Countries.find(
          (country) => country.code === ipStack?.country_code,
        ),
        ipStackState:
          USStates.find((state) => state.code === ipStack?.region_code) ||
          CanadaStates.find((state) => state.code === ipStack?.region_code),
      }}
    >
      {children}
    </IpStackContext.Provider>
  )
}

export const useIpStackContext = () => {
  const context = useContext(IpStackContext)
  if (!context) {
    throw new Error('context must be used within a Provider')
  }
  return context
}
