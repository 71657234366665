import { env } from 'src/env.mjs'
import { Language } from '../../config/Languages'

export interface CountryType {
  flag: string
  code: string
  label: Record<Language, string>
  phone: string
  regexZip: RegExp | null
  zipFormat: string | null
  zipNote: string
}

export const Countries: CountryType[] = [
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ad.png`,
    code: 'AD',
    label: {
      en: 'Andorra',
      de: 'Andorra',
      it: 'Andorra',
      fr: 'Andorra',
      es: 'Andorra',
    },
    phone: '+376',
    regexZip: /^[Aa][Dd]\d{3}$/,
    zipFormat: 'CCNNN',
    zipNote: 'Each parish now has its own post code.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ae.png`,
    code: 'AE',
    label: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
      it: 'United Arab Emirates',
      fr: 'United Arab Emirates',
      es: 'United Arab Emirates',
    },
    phone: '+971',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/af.png`,
    code: 'AF',
    label: {
      en: 'Afghanistan',
      de: 'Afghanistan',
      it: 'Afghanistan',
      fr: 'Afghanistan',
      es: 'Afghanistan',
    },
    phone: '+93',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'The first two digits (ranging from 10–43) correspond to the province, while the last two digits correspond either to the city/delivery zone (range 01–50) or to the district/delivery zone (range 51–99). Afghanistan Postal code lookup',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ag.png`,
    code: 'AG',
    label: {
      en: 'Antigua and Barbuda',
      de: 'Antigua und Barbuda',
      it: 'Antigua and Barbuda',
      fr: 'Antigua and Barbuda',
      es: 'Antigua and Barbuda',
    },
    phone: '+1-268',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ai.png`,
    code: 'AI',
    label: {
      en: 'Anguilla',
      de: 'Anguilla',
      it: 'Anguilla',
      fr: 'Anguilla',
      es: 'Anguilla',
    },
    phone: '+1-264',
    regexZip: /^[Aa][I][-][2][6][4][0]$/,
    zipFormat: 'AI-2640',
    zipNote: 'Single code used for all addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/al.png`,
    code: 'AL',
    label: {
      en: 'Albania',
      de: 'Albanien',
      it: 'Albania',
      fr: 'Albania',
      es: 'Albania',
    },
    phone: '+355',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'Introduced in 2006, gradually implemented throughout 2007.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/am.png`,
    code: 'AM',
    label: {
      en: 'Armenia',
      de: 'Armenien',
      it: 'Armenia',
      fr: 'Armenia',
      es: 'Armenia',
    },
    phone: '+374',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'Previously used NNNNNN system inherited from former Soviet Union.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ao.png`,
    code: 'AO',
    label: {
      en: 'Angola',
      de: 'Angola',
      it: 'Angola',
      fr: 'Angola',
      es: 'Angola',
    },
    phone: '+244',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ar.png`,
    code: 'AR',
    label: {
      en: 'Argentina',
      de: 'Argentinien',
      it: 'Argentina',
      fr: 'Argentina',
      es: 'Argentina',
    },
    phone: '+54',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'Codigo Postal Argentino (CPA), where the first A is the province code as in ISO 3166-2:AR, the four numbers are the old postal codes, the three last letters indicate a side of the block. Previously NNNN which &#10;o the minimum requirement as of 2006.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/as.png`,
    code: 'AS',
    label: {
      en: 'American Samoa',
      de: 'Amerikanisch-Samoa',
      it: 'American Samoa',
      fr: 'American Samoa',
      es: 'American Samoa',
    },
    phone: '+1-684',
    regexZip: /^\d{5}(-{1}\d{4,6})$/,
    zipFormat: 'NNNNN (optionally NNNNN-NNNN or NNNNN-NNNNNN)',
    zipNote: 'U.S. ZIP codes (range 96799)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/at.png`,
    code: 'AT',
    label: {
      en: 'Austria',
      de: 'Österreich',
      it: 'Austria',
      fr: 'Austria',
      es: 'Austria',
    },
    phone: '+43',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'The first digit denotes regions, which are partly identical to one of the nine provinces—called Bundesländer; the last the nearest post office in the area.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/au.png`,
    code: 'AU',
    label: {
      en: 'Australia',
      de: 'Australien',
      it: 'Australia',
      fr: 'Australia',
      es: 'Australia',
    },
    phone: '+61',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'In general, the first digit identifies the state or territory.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/aw.png`,
    code: 'AW',
    label: { en: 'Aruba', de: 'Aruba', it: 'Aruba', fr: 'Aruba', es: 'Aruba' },
    phone: '+297',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ax.png`,
    code: 'AX',
    label: {
      en: 'Alland Islands',
      de: 'Åland',
      it: 'Alland Islands',
      fr: 'Alland Islands',
      es: 'Alland Islands',
    },
    phone: '+358',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'With Finland, first two numbers are 22.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/az.png`,
    code: 'AZ',
    label: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
      it: 'Azerbaijan',
      fr: 'Azerbaijan',
      es: 'Azerbaijan',
    },
    phone: '+994',
    regexZip: /^[Aa][Zz]\d{4}$/,
    zipFormat: 'CCNNNN',
    zipNote:
      'Previously used NNNNNN system inherited from former Soviet Union.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ba.png`,
    code: 'BA',
    label: {
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina',
      it: 'Bosnia and Herzegovina',
      fr: 'Bosnia and Herzegovina',
      es: 'Bosnia and Herzegovina',
    },
    phone: '+387',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bb.png`,
    code: 'BB',
    label: {
      en: 'Barbados',
      de: 'Barbados',
      it: 'Barbados',
      fr: 'Barbados',
      es: 'Barbados',
    },
    phone: '+1-246',
    regexZip: /^[Aa][Zz]\d{5}$/,
    zipFormat: 'CCNNNNN',
    zipNote:
      'Only one postal code currently assigned. 11000 applies to the General Post Office building in Cheapside, Bridgetown, to enable delivery to Barbados by global package delivery companies whose software requires a postal code.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bd.png`,
    code: 'BD',
    label: {
      en: 'Bangladesh',
      de: 'Bangladesch',
      it: 'Bangladesh',
      fr: 'Bangladesh',
      es: 'Bangladesh',
    },
    phone: '+880',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/be.png`,
    code: 'BE',
    label: {
      en: 'Belgium',
      de: 'Belgien',
      it: 'Belgium',
      fr: 'Belgium',
      es: 'Belgium',
    },
    phone: '+32',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'In general, the first digit gives the province.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bf.png`,
    code: 'BF',
    label: {
      en: 'Burkina Faso',
      de: 'Burkina Faso',
      it: 'Burkina Faso',
      fr: 'Burkina Faso',
      es: 'Burkina Faso',
    },
    phone: '+226',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bg.png`,
    code: 'BG',
    label: {
      en: 'Bulgaria',
      de: 'Bulgarien',
      it: 'Bulgaria',
      fr: 'Bulgaria',
      es: 'Bulgaria',
    },
    phone: '+359',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bh.png`,
    code: 'BH',
    label: {
      en: 'Bahrain',
      de: 'Bahrain',
      it: 'Bahrain',
      fr: 'Bahrain',
      es: 'Bahrain',
    },
    phone: '+973',
    regexZip: /^\d{3,4}$/,
    zipFormat: 'NNN or NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bi.png`,
    code: 'BI',
    label: {
      en: 'Burundi',
      de: 'Burundi',
      it: 'Burundi',
      fr: 'Burundi',
      es: 'Burundi',
    },
    phone: '+257',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bj.png`,
    code: 'BJ',
    label: { en: 'Benin', de: 'Benin', it: 'Benin', fr: 'Benin', es: 'Benin' },
    phone: '+229',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bl.png`,
    code: 'BL',
    label: {
      en: 'Saint Barthelemy',
      de: 'Saint Barthélemy',
      it: 'Saint Barthelemy',
      fr: 'Saint Barthelemy',
      es: 'Saint Barthelemy',
    },
    phone: '+590',
    regexZip: /^97133$/,
    zipFormat: '97133',
    zipNote: 'Overseas Collectivity of France. French codes used.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bm.png`,
    code: 'BM',
    label: {
      en: 'Bermuda',
      de: 'Bermuda',
      it: 'Bermuda',
      fr: 'Bermuda',
      es: 'Bermuda',
    },
    phone: '+1-441',
    regexZip: /^[A-Za-z]{2}\s([A-Za-z]{2}|\d{2})$/,
    zipFormat: 'AA NN or AA AA',
    zipNote:
      'AA NN for street addresses, AA AA for P.O. Box addresses. The second half of the postcode identifies the street delivery walk (e.g.: Hamilton HM 12) or the PO Box number range (e.g.: Hamilton HM BX). See Postal codes in Bermuda.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bn.png`,
    code: 'BN',
    label: {
      en: 'Brunei Darussalam',
      de: 'Brunei Darussalam',
      it: 'Brunei Darussalam',
      fr: 'Brunei Darussalam',
      es: 'Brunei Darussalam',
    },
    phone: '+673',
    regexZip: /^[A-Za-z]{2}\d{4}$/,
    zipFormat: 'AANNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bo.png`,
    code: 'BO',
    label: {
      en: 'Bolivia',
      de: 'Bolivien',
      it: 'Bolivia',
      fr: 'Bolivia',
      es: 'Bolivia',
    },
    phone: '+591',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/br.png`,
    code: 'BR',
    label: {
      en: 'Brazil',
      de: 'Brasilien',
      it: 'Brazil',
      fr: 'Brazil',
      es: 'Brazil',
    },
    phone: '+55',
    regexZip: /^\d{5}-\d{3}$/,
    zipFormat: 'NNNNN-NNN',
    zipNote:
      'Código de Endereçamento Postal (CEP): -000 to -899 are used for streets, roads, avenues, boulevards; -900 to -959 are used for buildings with a high postal use; -960 to -969 are for promotional use; -970 to -989 are post offices and regular P.O. boxes; and -990 to -998 are used for community P.O. boxes. -999 is used for special services.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bs.png`,
    code: 'BS',
    label: {
      en: 'Bahamas',
      de: 'Bahamas',
      it: 'Bahamas',
      fr: 'Bahamas',
      es: 'Bahamas',
    },
    phone: '+1-242',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bt.png`,
    code: 'BT',
    label: {
      en: 'Bhutan',
      de: 'Bhutan',
      it: 'Bhutan',
      fr: 'Bhutan',
      es: 'Bhutan',
    },
    phone: '+975',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bw.png`,
    code: 'BW',
    label: {
      en: 'Botswana',
      de: 'Botsuana',
      it: 'Botswana',
      fr: 'Botswana',
      es: 'Botswana',
    },
    phone: '+267',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/by.png`,
    code: 'BY',
    label: {
      en: 'Belarus',
      de: 'Belarus',
      it: 'Belarus',
      fr: 'Belarus',
      es: 'Belarus',
    },
    phone: '+375',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'Retained system inherited from former Soviet Union.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/bz.png`,
    code: 'BZ',
    label: {
      en: 'Belize',
      de: 'Belize',
      it: 'Belize',
      fr: 'Belize',
      es: 'Belize',
    },
    phone: '+501',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ca.png`,
    code: 'CA',
    label: {
      en: 'Canada',
      de: 'Kanada',
      it: 'Canada',
      fr: 'Canada',
      es: 'Canada',
    },
    phone: '+1',
    regexZip:
      /^(?=[^\ddFfIiOoQqUu\d\s])[A-Za-z]\d(?=[^\ddFfIiOoQqUu\d\s])[A-Za-z]\s{0,1}\d(?=[^\ddFfIiOoQqUu\d\s])[A-Za-z]\d$/,
    zipFormat: 'ANA NAN',
    zipNote:
      'The system was gradually introduced starting in April 1971 in Ottawa. The letters D, F, I, O, Q, and U are not used to avoid confusion with other letters or numbers.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cc.png`,
    code: 'CC',
    label: {
      en: 'Cocos (Keeling) Islands',
      de: 'Kokosinseln(Keelinginseln)',
      it: 'Cocos (Keeling) Islands',
      fr: 'Cocos (Keeling) Islands',
      es: 'Cocos (Keeling) Islands',
    },
    phone: '+61',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'Part of the Australian postal code system.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cd.png`,
    code: 'CD',
    label: {
      en: 'Congo, Democratic Republic of the',
      de: 'Kongo, demokratische Republik',
      it: 'Congo, Democratic Republic of the',
      fr: 'Congo, Democratic Republic of the',
      es: 'Congo, Democratic Republic of the',
    },
    phone: '+243',
    regexZip: /^[Cc][Dd]$/,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cf.png`,
    code: 'CF',
    label: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik',
      it: 'Central African Republic',
      fr: 'Central African Republic',
      es: 'Central African Republic',
    },
    phone: '+236',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cg.png`,
    code: 'CG',
    label: {
      en: 'Congo, Republic of the',
      de: 'Kongo',
      it: 'Congo, Republic of the',
      fr: 'Congo, Republic of the',
      es: 'Congo, Republic of the',
    },
    phone: '+242',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ch.png`,
    code: 'CH',
    label: {
      en: 'Switzerland',
      de: 'Schweiz',
      it: 'Switzerland',
      fr: 'Switzerland',
      es: 'Switzerland',
    },
    phone: '+41',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ci.png`,
    code: 'CI',
    label: {
      en: "Cote d'Ivoire",
      de: "Côte d'Ivoire",
      it: "Cote d'Ivoire",
      fr: "Cote d'Ivoire",
      es: "Cote d'Ivoire",
    },
    phone: '+225',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ck.png`,
    code: 'CK',
    label: {
      en: 'Cook Islands',
      de: 'Cookinseln',
      it: 'Cook Islands',
      fr: 'Cook Islands',
      es: 'Cook Islands',
    },
    phone: '+682',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cl.png`,
    code: 'CL',
    label: { en: 'Chile', de: 'Chile', it: 'Chile', fr: 'Chile', es: 'Chile' },
    phone: '+56',
    regexZip: /^\d{7}$/,
    zipFormat: 'NNNNNNN',
    zipNote: 'May only be required for bulk mail.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cm.png`,
    code: 'CM',
    label: {
      en: 'Cameroon',
      de: 'Kamerun',
      it: 'Cameroon',
      fr: 'Cameroon',
      es: 'Cameroon',
    },
    phone: '+237',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cn.png`,
    code: 'CN',
    label: { en: 'China', de: 'China', it: 'China', fr: 'China', es: 'China' },
    phone: '+86',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote:
      'A postal code or youbian (邮编) in a subordinate division will have the same first two digits as its governing one (see Political divisions of China. The postal services in Macau or Hong Kong Special Administrative Regions remain separate from Mainland China, with no post code system currently used.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/co.png`,
    code: 'CO',
    label: {
      en: 'Colombia',
      de: 'Kolumbien',
      it: 'Colombia',
      fr: 'Colombia',
      es: 'Colombia',
    },
    phone: '+57',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'First NN = 32 departments Códigos Postales | 4-72',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cr.png`,
    code: 'CR',
    label: {
      en: 'Costa Rica',
      de: 'Costa Rica',
      it: 'Costa Rica',
      fr: 'Costa Rica',
      es: 'Costa Rica',
    },
    phone: '+506',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'First codes the provinces, next two the canton, last two the district.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cu.png`,
    code: 'CU',
    label: { en: 'Cuba', de: 'Kuba', it: 'Cuba', fr: 'Cuba', es: 'Cuba' },
    phone: '+53',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'May only be required for bulk mail. The letters CP are frequently used before the postal code. This is not a country code, but an abbreviation for "codigo postal" or postal code.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cv.png`,
    code: 'CV',
    label: {
      en: 'Cape Verde',
      de: 'Kap Verde',
      it: 'Cape Verde',
      fr: 'Cape Verde',
      es: 'Cape Verde',
    },
    phone: '+238',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'The first digit indicates the island.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cx.png`,
    code: 'CX',
    label: {
      en: 'Christmas Island',
      de: 'Weihnachtsinsel',
      it: 'Christmas Island',
      fr: 'Christmas Island',
      es: 'Christmas Island',
    },
    phone: '+61',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'Part of the Australian postal code system.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cy.png`,
    code: 'CY',
    label: {
      en: 'Cyprus',
      de: 'Zypern',
      it: 'Cyprus',
      fr: 'Cyprus',
      es: 'Cyprus',
    },
    phone: '+357',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      "Post code system covers whole island, but not used in Northern Cyprus where 'Mersin 10, Turkey' is used instead.",
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/cz.png`,
    code: 'CZ',
    label: {
      en: 'Czech Republic',
      de: 'Tschechische Republik',
      it: 'Czech Republic',
      fr: 'Czech Republic',
      es: 'Czech Republic',
    },
    phone: '+420',
    regexZip: /^\d{3}\s\d{2}$/,
    zipFormat: 'NNN NN',
    zipNote:
      'With Slovak Republic, Poštovní směrovací číslo (PSČ) - postal routing number.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/de.png`,
    code: 'DE',
    label: {
      en: 'Germany',
      de: 'Deutschland',
      it: 'Germany',
      fr: 'Germany',
      es: 'Germany',
    },
    phone: '+49',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'Postleitzahl (PLZ)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/dj.png`,
    code: 'DJ',
    label: {
      en: 'Djibouti',
      de: 'Dschibuti',
      it: 'Djibouti',
      fr: 'Djibouti',
      es: 'Djibouti',
    },
    phone: '+253',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/dk.png`,
    code: 'DK',
    label: {
      en: 'Denmark',
      de: 'Dänemark',
      it: 'Denmark',
      fr: 'Denmark',
      es: 'Denmark',
    },
    phone: '+45',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'Numbering follows the dispatch of postal trains from Copenhagen.[3] Also used by Greenland, e.g.: DK-3900 Nuuk.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/dm.png`,
    code: 'DM',
    label: {
      en: 'Dominica',
      de: 'Dominica',
      it: 'Dominica',
      fr: 'Dominica',
      es: 'Dominica',
    },
    phone: '+1-767',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/do.png`,
    code: 'DO',
    label: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
      it: 'Dominican Republic',
      fr: 'Dominican Republic',
      es: 'Dominican Republic',
    },
    phone: '+1-809',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/dz.png`,
    code: 'DZ',
    label: {
      en: 'Algeria',
      de: 'Algerien',
      it: 'Algeria',
      fr: 'Algeria',
      es: 'Algeria',
    },
    phone: '+213',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'First two as in ISO 3166-2:DZ',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ec.png`,
    code: 'EC',
    label: {
      en: 'Ecuador',
      de: 'Ecuador',
      it: 'Ecuador',
      fr: 'Ecuador',
      es: 'Ecuador',
    },
    phone: '+593',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ee.png`,
    code: 'EE',
    label: {
      en: 'Estonia',
      de: 'Estland',
      it: 'Estonia',
      fr: 'Estonia',
      es: 'Estonia',
    },
    phone: '+372',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/eg.png`,
    code: 'EG',
    label: {
      en: 'Egypt',
      de: 'Ägypten',
      it: 'Egypt',
      fr: 'Egypt',
      es: 'Egypt',
    },
    phone: '+20',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/er.png`,
    code: 'ER',
    label: {
      en: 'Eritrea',
      de: 'Eritrea',
      it: 'Eritrea',
      fr: 'Eritrea',
      es: 'Eritrea',
    },
    phone: '+291',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/es.png`,
    code: 'ES',
    label: {
      en: 'Spain',
      de: 'Spanien',
      it: 'Spain',
      fr: 'Spain',
      es: 'Spain',
    },
    phone: '+34',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'First two indicate the province, range 01-52',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/et.png`,
    code: 'ET',
    label: {
      en: 'Ethiopia',
      de: 'Äthiopien',
      it: 'Ethiopia',
      fr: 'Ethiopia',
      es: 'Ethiopia',
    },
    phone: '+251',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'The code is only used on a trial basis for Addis Ababa addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/fi.png`,
    code: 'FI',
    label: {
      en: 'Finland',
      de: 'Finnland',
      it: 'Finland',
      fr: 'Finland',
      es: 'Finland',
    },
    phone: '+358',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'A lower first digit indicates a place in south (for example 00100 Helsinki), a higher indicates a place further to north (99800 in Ivalo). The last digit is usually 0, except for postal codes for PO Box number ranges, in which case it is 1. Country code for Finland: "FI". In the Åland Islands, the postal code is prefixed with "AX", not "FI". Some postal codes for rural settlements may end with 5, and there are some unique postal codes for large companies and institutions, e.g. 00014 HELSINGIN YLIOPISTO (university), 00102 EDUSKUNTA (parliament), 00020 NORDEA (a major Scandinavian bank).',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/fj.png`,
    code: 'FJ',
    label: { en: 'Fiji', de: 'Fidschi', it: 'Fiji', fr: 'Fiji', es: 'Fiji' },
    phone: '+679',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/fk.png`,
    code: 'FK',
    label: {
      en: 'Falkland Islands (Malvinas)',
      de: 'Falklandinseln (Malwinen)',
      it: 'Falkland Islands (Malvinas)',
      fr: 'Falkland Islands (Malvinas)',
      es: 'Falkland Islands (Malvinas)',
    },
    phone: '+500',
    regexZip: /^[Ff][Ii][Qq]{2}\s{0,1}[1][Zz]{2}$/,
    zipFormat: 'AAAANAA one code: FIQQ 1ZZ',
    zipNote: 'UK territory, but not UK postcode',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/fm.png`,
    code: 'FM',
    label: {
      en: 'Micronesia, Federated States of',
      de: 'Föderierte Staaten von Mikronesien',
      it: 'Micronesia, Federated States of',
      fr: 'Micronesia, Federated States of',
      es: 'Micronesia, Federated States of',
    },
    phone: '+691',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'US ZIP Code. Range 96941 - 96944.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/fo.png`,
    code: 'FO',
    label: {
      en: 'Faroe Islands',
      de: 'Färöer',
      it: 'Faroe Islands',
      fr: 'Faroe Islands',
      es: 'Faroe Islands',
    },
    phone: '+298',
    regexZip: /^\d{3}$/,
    zipFormat: 'NNN',
    zipNote:
      'Self-governing territory within the Kingdom of Denmark, but not Danish postcode.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/fr.png`,
    code: 'FR',
    label: {
      en: 'France',
      de: 'Frankreich',
      it: 'France',
      fr: 'France',
      es: 'France',
    },
    phone: '+33',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The first two digits give the département number, while in Paris, Lyon and Marseille, the last two digits of the postal code indicates the arrondissement. Both of the 2 corsican départements use "20" as the first two digits. Also used by French overseas departments and territories. Monaco is also part of the French postal code system, but the country code MC- is used for Monegasque addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ga.png`,
    code: 'GA',
    label: { en: 'Gabon', de: 'Gabun', it: 'Gabon', fr: 'Gabon', es: 'Gabon' },
    phone: '+241',
    regexZip: /^\d{2}\s[a-zA-Z-_ ]\s\d{2}$/,
    zipFormat: 'NN [city name] NN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gb.png`,
    code: 'GB',
    label: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich',
      it: 'United Kingdom',
      fr: 'United Kingdom',
      es: 'United Kingdom',
    },
    phone: '+44',
    regexZip:
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
    zipFormat: 'A(A)N(A/N)NAA (A[A]N[A/N] NAA)',
    zipNote:
      'Known as the postcode. The first letter(s) indicate the postal area, such as the town or part of London. Placed on a separate line below the city (or county, if used). The UK postcode is made up of two parts separated by a space. These are known as the outward postcode and the inward postcode. The outward postcode is always one of the following formats: AN, ANN, AAN, AANN, ANA, AANA, AAA. The inward postcode is always formatted as NAA. A valid inward postcode never contains the letters: C, I, K, M, O or V. The British Forces Post Office has a different system, but as of 2012 has also adopted UK-style postcodes that begin with "BF1" for electronic compatibility.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gd.png`,
    code: 'GD',
    label: {
      en: 'Grenada',
      de: 'Grenada',
      it: 'Grenada',
      fr: 'Grenada',
      es: 'Grenada',
    },
    phone: '+1-473',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ge.png`,
    code: 'GE',
    label: {
      en: 'Georgia',
      de: 'Georgien',
      it: 'Georgia',
      fr: 'Georgia',
      es: 'Georgia',
    },
    phone: '+995',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gf.png`,
    code: 'GF',
    label: {
      en: 'French Guiana',
      de: 'Französisch Guayana',
      it: 'French Guiana',
      fr: 'French Guiana',
      es: 'French Guiana',
    },
    phone: '+594',
    regexZip: /^973\d{2}$/,
    zipFormat: '973NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 97300 - 97390.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gg.png`,
    code: 'GG',
    label: {
      en: 'Guernsey',
      de: 'Guernsey',
      it: 'Guernsey',
      fr: 'Guernsey',
      es: 'Guernsey',
    },
    phone: '+44',
    regexZip: /^[A-Za-z]{2}\d\s{0,1}\d[A-Za-z]{2}$/,
    zipFormat: 'AAN NAA, AANN NAA',
    zipNote: 'UK-format postcode (first two letters are always GY not GG)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gh.png`,
    code: 'GH',
    label: { en: 'Ghana', de: 'Ghana', it: 'Ghana', fr: 'Ghana', es: 'Ghana' },
    phone: '+233',
    regexZip: null,
    zipFormat: null,
    zipNote: '[citation needed]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gi.png`,
    code: 'GI',
    label: {
      en: 'Gibraltar',
      de: 'Gibraltar',
      it: 'Gibraltar',
      fr: 'Gibraltar',
      es: 'Gibraltar',
    },
    phone: '+350',
    regexZip: /^[Gg][Xx][1]{2}\s{0,1}[1][Aa]{2}$/,
    zipFormat: 'GX11 1AA',
    zipNote: 'Single code used for all addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gl.png`,
    code: 'GL',
    label: {
      en: 'Greenland',
      de: 'Grönland',
      it: 'Greenland',
      fr: 'Greenland',
      es: 'Greenland',
    },
    phone: '+299',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'Part of the Danish postal code system.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gm.png`,
    code: 'GM',
    label: {
      en: 'Gambia',
      de: 'Gambia',
      it: 'Gambia',
      fr: 'Gambia',
      es: 'Gambia',
    },
    phone: '+220',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gn.png`,
    code: 'GN',
    label: {
      en: 'Guinea',
      de: 'Guinea',
      it: 'Guinea',
      fr: 'Guinea',
      es: 'Guinea',
    },
    phone: '+224',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gp.png`,
    code: 'GP',
    label: {
      en: 'Guadeloupe',
      de: 'Guadeloupe',
      it: 'Guadeloupe',
      fr: 'Guadeloupe',
      es: 'Guadeloupe',
    },
    phone: '+590',
    regexZip: /^971\d{2}$/,
    zipFormat: '971NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 97100 - 97190.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gq.png`,
    code: 'GQ',
    label: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea',
      it: 'Equatorial Guinea',
      fr: 'Equatorial Guinea',
      es: 'Equatorial Guinea',
    },
    phone: '+240',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gr.png`,
    code: 'GR',
    label: {
      en: 'Greece',
      de: 'Griechenland',
      it: 'Greece',
      fr: 'Greece',
      es: 'Greece',
    },
    phone: '+30',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gs.png`,
    code: 'GS',
    label: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'Südgeorgien',
      it: 'South Georgia and the South Sandwich Islands',
      fr: 'South Georgia and the South Sandwich Islands',
      es: 'South Georgia and the South Sandwich Islands',
    },
    phone: '+500',
    regexZip: /^[Ss][Ii][Qq]{2}\s{0,1}[1][Zz]{2}$/,
    zipFormat: 'SIQQ 1ZZ',
    zipNote: 'One code for all addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gt.png`,
    code: 'GT',
    label: {
      en: 'Guatemala',
      de: 'Guatemala',
      it: 'Guatemala',
      fr: 'Guatemala',
      es: 'Guatemala',
    },
    phone: '+502',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The first two numbers identify the department, the third number the route and the last two the office.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gu.png`,
    code: 'GU',
    label: { en: 'Guam', de: 'Guam', it: 'Guam', fr: 'Guam', es: 'Guam' },
    phone: '+1-671',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'U.S. ZIP codes. Range 96910 - 96932.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gw.png`,
    code: 'GW',
    label: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
      it: 'Guinea-Bissau',
      fr: 'Guinea-Bissau',
      es: 'Guinea-Bissau',
    },
    phone: '+245',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/gy.png`,
    code: 'GY',
    label: {
      en: 'Guyana',
      de: 'Guyana',
      it: 'Guyana',
      fr: 'Guyana',
      es: 'Guyana',
    },
    phone: '+592',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/hk.png`,
    code: 'HK',
    label: {
      en: 'Hong Kong',
      de: 'Hongkong',
      it: 'Hong Kong',
      fr: 'Hong Kong',
      es: 'Hong Kong',
    },
    phone: '+852',
    regexZip: null,
    zipFormat: null,
    zipNote:
      '[1] The dummy postal code of Hong Kong is 999077 but it is unnecessary in fact',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/hn.png`,
    code: 'HN',
    label: {
      en: 'Honduras',
      de: 'Honduras',
      it: 'Honduras',
      fr: 'Honduras',
      es: 'Honduras',
    },
    phone: '+504',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/hr.png`,
    code: 'HR',
    label: {
      en: 'Croatia',
      de: 'Kroatien',
      it: 'Croatia',
      fr: 'Croatia',
      es: 'Croatia',
    },
    phone: '+385',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ht.png`,
    code: 'HT',
    label: { en: 'Haiti', de: 'Haiti', it: 'Haiti', fr: 'Haiti', es: 'Haiti' },
    phone: '+509',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/hu.png`,
    code: 'HU',
    label: {
      en: 'Hungary',
      de: 'Ungarn',
      it: 'Hungary',
      fr: 'Hungary',
      es: 'Hungary',
    },
    phone: '+36',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'The code defines an area, usually one code per settlement except the six largest towns. One code can identify more (usually) small settlements as well.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/id.png`,
    code: 'ID',
    label: {
      en: 'Indonesia',
      de: 'Indonesien',
      it: 'Indonesia',
      fr: 'Indonesia',
      es: 'Indonesia',
    },
    phone: '+62',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'Kode Pos. Included East Timor (ranges 88xxx and 89xxx) until 1999, no longer used. For Indonesia postal code information visit [2]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ie.png`,
    code: 'IE',
    label: {
      en: 'Ireland',
      de: 'Irland',
      it: 'Ireland',
      fr: 'Ireland',
      es: 'Ireland',
    },
    phone: '+353',
    regexZip: null,
    zipFormat: null,
    zipNote:
      'Currently no postal codes; however, Dublin is divided into postal districts on syntax Dublin 9. A national post code system is planned. See also Republic of Ireland postal addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/il.png`,
    code: 'IL',
    label: {
      en: 'Israel',
      de: 'Israel',
      it: 'Israel',
      fr: 'Israel',
      es: 'Israel',
    },
    phone: '+972',
    regexZip: /^\d{7}$/,
    zipFormat: 'NNNNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/im.png`,
    code: 'IM',
    label: {
      en: 'Isle of Man',
      de: 'Isle Of Man',
      it: 'Isle of Man',
      fr: 'Isle of Man',
      es: 'Isle of Man',
    },
    phone: '+44',
    regexZip: /^[Ii[Mm]\d{1,2}\s\d\[A-Z]{2}$/,
    zipFormat: 'CCN NAA, CCNN NAA',
    zipNote: 'UK-format postcode. The first two letters are always IM.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/in.png`,
    code: 'IN',
    label: { en: 'India', de: 'Indien', it: 'India', fr: 'India', es: 'India' },
    phone: '+91',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'Postal Index Number (PIN)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/io.png`,
    code: 'IO',
    label: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium im Indischen Ozean',
      it: 'British Indian Ocean Territory',
      fr: 'British Indian Ocean Territory',
      es: 'British Indian Ocean Territory',
    },
    phone: '+246',
    regexZip: /^[Bb]{2}[Nn][Dd]\s{0,1}[1][Zz]{2}$/,
    zipFormat: 'AAAANAA one code: BBND 1ZZ',
    zipNote: 'UK territory, but not UK postcode.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/iq.png`,
    code: 'IQ',
    label: { en: 'Iraq', de: 'Irak', it: 'Iraq', fr: 'Iraq', es: 'Iraq' },
    phone: '+964',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ir.png`,
    code: 'IR',
    label: {
      en: 'Iran, Islamic Republic of',
      de: 'Islamische Republik Iran',
      it: 'Iran, Islamic Republic of',
      fr: 'Iran, Islamic Republic of',
      es: 'Iran, Islamic Republic of',
    },
    phone: '+98',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '(Persian: کد پستی)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/is.png`,
    code: 'IS',
    label: {
      en: 'Iceland',
      de: 'Island',
      it: 'Iceland',
      fr: 'Iceland',
      es: 'Iceland',
    },
    phone: '+354',
    regexZip: /^\d{3}$/,
    zipFormat: 'NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/it.png`,
    code: 'IT',
    label: {
      en: 'Italy',
      de: 'Italien',
      it: 'Italy',
      fr: 'Italy',
      es: 'Italy',
    },
    phone: '+39',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'Codice di Avviamento Postale (CAP). Also used by San Marino and Vatican City. First two digits identify province with some exceptions, because there are more than 100 provinces.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/je.png`,
    code: 'JE',
    label: {
      en: 'Jersey',
      de: 'Jersey',
      it: 'Jersey',
      fr: 'Jersey',
      es: 'Jersey',
    },
    phone: '+44',
    regexZip: /^[Jj][Ee]\d\s{0,1}\d[A-Za-z]{2}$/,
    zipFormat: 'CCN NAA',
    zipNote: 'UK-format postcode.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/jm.png`,
    code: 'JM',
    label: {
      en: 'Jamaica',
      de: 'Jamaika',
      it: 'Jamaica',
      fr: 'Jamaica',
      es: 'Jamaica',
    },
    phone: '+1-876',
    regexZip: /^\d{2}$/,
    zipFormat: 'Before suspension: CCAAANN &#10;After suspension: NN',
    zipNote:
      "Jamaica currently has no national postal code system, except for Kingston and Lower St. Andrew, which are divided into postal districts numbered 1-20[4] &#10;Before the 2007 suspension, the first two letters of a national post code were always 'JM' (for Jamaica) while the third was for one of the four zones (A-D) into which the island was divided. The last two letters were for the parish, while the two digits were for the local post office.[5]",
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/jo.png`,
    code: 'JO',
    label: {
      en: 'Jordan',
      de: 'Jordanien',
      it: 'Jordan',
      fr: 'Jordan',
      es: 'Jordan',
    },
    phone: '+962',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'Deliveries to PO Boxes only.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/jp.png`,
    code: 'JP',
    label: { en: 'Japan', de: 'Japan', it: 'Japan', fr: 'Japan', es: 'Japan' },
    phone: '+81',
    regexZip: /^\d{3}-d{4}/,
    zipFormat: 'NNN-NNNN',
    zipNote: 'See also Japanese addressing system.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ke.png`,
    code: 'KE',
    label: { en: 'Kenya', de: 'Kenia', it: 'Kenya', fr: 'Kenya', es: 'Kenya' },
    phone: '+254',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      "Deliveries to PO Boxes only. The postal code refers to the post office at which the receiver's P. O. Box is located.",
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kg.png`,
    code: 'KG',
    label: {
      en: 'Kyrgyzstan',
      de: 'Kirgisische Republik',
      it: 'Kyrgyzstan',
      fr: 'Kyrgyzstan',
      es: 'Kyrgyzstan',
    },
    phone: '+996',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kh.png`,
    code: 'KH',
    label: {
      en: 'Cambodia',
      de: 'Kambodscha',
      it: 'Cambodia',
      fr: 'Cambodia',
      es: 'Cambodia',
    },
    phone: '+855',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ki.png`,
    code: 'KI',
    label: {
      en: 'Kiribati',
      de: 'Kiribati',
      it: 'Kiribati',
      fr: 'Kiribati',
      es: 'Kiribati',
    },
    phone: '+686',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/km.png`,
    code: 'KM',
    label: {
      en: 'Comoros',
      de: 'Komoren',
      it: 'Comoros',
      fr: 'Comoros',
      es: 'Comoros',
    },
    phone: '+269',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kn.png`,
    code: 'KN',
    label: {
      en: 'Saint Kitts and Nevis',
      de: 'St.Kitts und Nevis',
      it: 'Saint Kitts and Nevis',
      fr: 'Saint Kitts and Nevis',
      es: 'Saint Kitts and Nevis',
    },
    phone: '+1-869',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kp.png`,
    code: 'KP',
    label: {
      en: "Korea, Democratic People's Republic of",
      de: 'Korea, demokratische Volksrepublik',
      it: "Korea, Democratic People's Republic of",
      fr: "Korea, Democratic People's Republic of",
      es: "Korea, Democratic People's Republic of",
    },
    phone: '+850',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kr.png`,
    code: 'KR',
    label: {
      en: 'Korea, Republic of',
      de: 'Korea',
      it: 'Korea, Republic of',
      fr: 'Korea, Republic of',
      es: 'Korea, Republic of',
    },
    phone: '+82',
    regexZip: /^\d{5}/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kw.png`,
    code: 'KW',
    label: {
      en: 'Kuwait',
      de: 'Kuwait',
      it: 'Kuwait',
      fr: 'Kuwait',
      es: 'Kuwait',
    },
    phone: '+965',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The first two digits represent the sector and the last three digits represents the post office.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ky.png`,
    code: 'KY',
    label: {
      en: 'Cayman Islands',
      de: 'Kaimaninseln',
      it: 'Cayman Islands',
      fr: 'Cayman Islands',
      es: 'Cayman Islands',
    },
    phone: '+1-345',
    regexZip: /^[Kk][Yy]\d[-\s]{0,1}\d{4}$/,
    zipFormat: 'CCN-NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/kz.png`,
    code: 'KZ',
    label: {
      en: 'Kazakhstan',
      de: 'Kasachstan',
      it: 'Kazakhstan',
      fr: 'Kazakhstan',
      es: 'Kazakhstan',
    },
    phone: '+7',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: '[6]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/la.png`,
    code: 'LA',
    label: {
      en: "Lao People's Democratic Republic",
      de: 'Laos, demokratische Volksrepublik',
      it: "Lao People's Democratic Republic",
      fr: "Lao People's Democratic Republic",
      es: "Lao People's Democratic Republic",
    },
    phone: '+856',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lb.png`,
    code: 'LB',
    label: {
      en: 'Lebanon',
      de: 'Libanon',
      it: 'Lebanon',
      fr: 'Lebanon',
      es: 'Lebanon',
    },
    phone: '+961',
    regexZip: /^\d{4}\s{0,1}\d{4}$/,
    zipFormat: 'NNNN NNNN',
    zipNote:
      'The first four digits represent the region or postal zone,the last four digits represent the building see also Lebanon Postal code website.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lc.png`,
    code: 'LC',
    label: {
      en: 'Saint Lucia',
      de: 'St.Lucia',
      it: 'Saint Lucia',
      fr: 'Saint Lucia',
      es: 'Saint Lucia',
    },
    phone: '+1-758',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/li.png`,
    code: 'LI',
    label: {
      en: 'Liechtenstein',
      de: 'Liechtenstein',
      it: 'Liechtenstein',
      fr: 'Liechtenstein',
      es: 'Liechtenstein',
    },
    phone: '+423',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'With Switzerland, ordered from west to east. Range 9485 - 9498.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lk.png`,
    code: 'LK',
    label: {
      en: 'Sri Lanka',
      de: 'Sri Lanka',
      it: 'Sri Lanka',
      fr: 'Sri Lanka',
      es: 'Sri Lanka',
    },
    phone: '+94',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'Reference: http://mohanjith.net/ZIPLook/ Incorporates Colombo postal districts, e.g.: Colombo 1 is "00100". You can search for specific postal codes here.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lr.png`,
    code: 'LR',
    label: {
      en: 'Liberia',
      de: 'Liberia',
      it: 'Liberia',
      fr: 'Liberia',
      es: 'Liberia',
    },
    phone: '+231',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ls.png`,
    code: 'LS',
    label: {
      en: 'Lesotho',
      de: 'Lesotho',
      it: 'Lesotho',
      fr: 'Lesotho',
      es: 'Lesotho',
    },
    phone: '+266',
    regexZip: /^\d{3}$/,
    zipFormat: 'NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lt.png`,
    code: 'LT',
    label: {
      en: 'Lithuania',
      de: 'Litauen',
      it: 'Lithuania',
      fr: 'Lithuania',
      es: 'Lithuania',
    },
    phone: '+370',
    regexZip: /^[Ll][Tt][- ]{0,1}\d{5}$/,
    zipFormat: 'LT-NNNNN',
    zipNote:
      'References: http://www.post.lt/en/help/postal-code-search. Previously 9999 which was actually the old Soviet 999999 format code with the first 2 digits dropped.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lu.png`,
    code: 'LU',
    label: {
      en: 'Luxembourg',
      de: 'Luxemburg',
      it: 'Luxembourg',
      fr: 'Luxembourg',
      es: 'Luxembourg',
    },
    phone: '+352',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'References: http://www.upu.int/post_code/en/countries/LUX.pdf',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/lv.png`,
    code: 'LV',
    label: {
      en: 'Latvia',
      de: 'Lettland',
      it: 'Latvia',
      fr: 'Latvia',
      es: 'Latvia',
    },
    phone: '+371',
    regexZip: /^[Ll][Vv][- ]{0,1}\d{4}$/,
    zipFormat: 'LV-NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ly.png`,
    code: 'LY',
    label: { en: 'Libya', de: 'Libyen', it: 'Libya', fr: 'Libya', es: 'Libya' },
    phone: '+218',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ma.png`,
    code: 'MA',
    label: {
      en: 'Morocco',
      de: 'Marokko',
      it: 'Morocco',
      fr: 'Morocco',
      es: 'Morocco',
    },
    phone: '+212',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mc.png`,
    code: 'MC',
    label: {
      en: 'Monaco',
      de: 'Monaco',
      it: 'Monaco',
      fr: 'Monaco',
      es: 'Monaco',
    },
    phone: '+377',
    regexZip: /^980\d{2}$/,
    zipFormat: '980NN',
    zipNote:
      'Uses the French Postal System, but with an "MC" Prefix for Monaco.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/md.png`,
    code: 'MD',
    label: {
      en: 'Moldova, Republic of',
      de: 'Republik Moldau',
      it: 'Moldova, Republic of',
      fr: 'Moldova, Republic of',
      es: 'Moldova, Republic of',
    },
    phone: '+373',
    regexZip: /^[Mm][Dd][- ]{0,1}\d{4}$/,
    zipFormat: 'CCNNNN (CC-NNNN)',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/me.png`,
    code: 'ME',
    label: {
      en: 'Montenegro',
      de: 'Montenegro',
      it: 'Montenegro',
      fr: 'Montenegro',
      es: 'Montenegro',
    },
    phone: '+382',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mf.png`,
    code: 'MF',
    label: {
      en: 'Saint Martin (French part)',
      de: 'Saint Martin',
      it: 'Saint Martin (French part)',
      fr: 'Saint Martin (French part)',
      es: 'Saint Martin (French part)',
    },
    phone: '+590',
    regexZip: /^97150$/,
    zipFormat: '97150',
    zipNote: 'Overseas Collectivity of France. French codes used.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mg.png`,
    code: 'MG',
    label: {
      en: 'Madagascar',
      de: 'Madagaskar',
      it: 'Madagascar',
      fr: 'Madagascar',
      es: 'Madagascar',
    },
    phone: '+261',
    regexZip: /^\d{3}$/,
    zipFormat: 'NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mh.png`,
    code: 'MH',
    label: {
      en: 'Marshall Islands',
      de: 'Marshallinseln',
      it: 'Marshall Islands',
      fr: 'Marshall Islands',
      es: 'Marshall Islands',
    },
    phone: '+692',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'U.S. ZIP codes. Range 96960 - 96970.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ml.png`,
    code: 'ML',
    label: { en: 'Mali', de: 'Mali', it: 'Mali', fr: 'Mali', es: 'Mali' },
    phone: '+223',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mm.png`,
    code: 'MM',
    label: {
      en: 'Myanmar',
      de: 'Myanmar',
      it: 'Myanmar',
      fr: 'Myanmar',
      es: 'Myanmar',
    },
    phone: '+95',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mn.png`,
    code: 'MN',
    label: {
      en: 'Mongolia',
      de: 'Mongolei',
      it: 'Mongolia',
      fr: 'Mongolia',
      es: 'Mongolia',
    },
    phone: '+976',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'First digit: region / zone&#10;Second digit: province / district&#10;Last three digits: locality / delivery block[7]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mo.png`,
    code: 'MO',
    label: { en: 'Macao', de: 'Macau', it: 'Macao', fr: 'Macao', es: 'Macao' },
    phone: '+853',
    regexZip: null,
    zipFormat: null,
    zipNote: '[2]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mp.png`,
    code: 'MP',
    label: {
      en: 'Northern Mariana Islands',
      de: 'Nördliche Marianen',
      it: 'Northern Mariana Islands',
      fr: 'Northern Mariana Islands',
      es: 'Northern Mariana Islands',
    },
    phone: '+1-670',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'U.S. ZIP codes. Range 96950 - 96952.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mq.png`,
    code: 'MQ',
    label: {
      en: 'Martinique',
      de: 'Martinique',
      it: 'Martinique',
      fr: 'Martinique',
      es: 'Martinique',
    },
    phone: '+596',
    regexZip: /^972\d{2}$/,
    zipFormat: '972NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 97200 - 97290.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mr.png`,
    code: 'MR',
    label: {
      en: 'Mauritania',
      de: 'Mauretanien',
      it: 'Mauritania',
      fr: 'Mauritania',
      es: 'Mauritania',
    },
    phone: '+222',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ms.png`,
    code: 'MS',
    label: {
      en: 'Montserrat',
      de: 'Montserrat',
      it: 'Montserrat',
      fr: 'Montserrat',
      es: 'Montserrat',
    },
    phone: '+1-664',
    regexZip: /^[Mm][Ss][Rr]\s{0,1}\d{4}$/,
    zipFormat: 'MSR 1110-1350',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mt.png`,
    code: 'MT',
    label: { en: 'Malta', de: 'Malta', it: 'Malta', fr: 'Malta', es: 'Malta' },
    phone: '+356',
    regexZip: /^[A-Za-z]{3}\s{0,1}\d{4}$/,
    zipFormat: 'AAANNNN (AAA NNNN)',
    zipNote: 'Kodiċi Postali',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mu.png`,
    code: 'MU',
    label: {
      en: 'Mauritius',
      de: 'Mauritius',
      it: 'Mauritius',
      fr: 'Mauritius',
      es: 'Mauritius',
    },
    phone: '+230',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mv.png`,
    code: 'MV',
    label: {
      en: 'Maldives',
      de: 'Malediven',
      it: 'Maldives',
      fr: 'Maldives',
      es: 'Maldives',
    },
    phone: '+960',
    regexZip: /^\d{4,5}$/,
    zipFormat: 'NNNN, NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mw.png`,
    code: 'MW',
    label: {
      en: 'Malawi',
      de: 'Malawi',
      it: 'Malawi',
      fr: 'Malawi',
      es: 'Malawi',
    },
    phone: '+265',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mx.png`,
    code: 'MX',
    label: {
      en: 'Mexico',
      de: 'Mexiko',
      it: 'Mexico',
      fr: 'Mexico',
      es: 'Mexico',
    },
    phone: '+52',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The first two digits identify the state (or a part thereof), except for Nos. 00 to 16, which indicate delegaciones (boroughs) of the Federal District (Mexico City).',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/my.png`,
    code: 'MY',
    label: {
      en: 'Malaysia',
      de: 'Malaysia',
      it: 'Malaysia',
      fr: 'Malaysia',
      es: 'Malaysia',
    },
    phone: '+60',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/mz.png`,
    code: 'MZ',
    label: {
      en: 'Mozambique',
      de: 'Mosambik',
      it: 'Mozambique',
      fr: 'Mozambique',
      es: 'Mozambique',
    },
    phone: '+258',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/na.png`,
    code: 'NA',
    label: {
      en: 'Namibia',
      de: 'Namibia',
      it: 'Namibia',
      fr: 'Namibia',
      es: 'Namibia',
    },
    phone: '+264',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'Postal Code ranges from 9000-9299 (NOTE: mainly 9000 is used)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/nc.png`,
    code: 'NC',
    label: {
      en: 'New Caledonia',
      de: 'Neukaledonien',
      it: 'New Caledonia',
      fr: 'New Caledonia',
      es: 'New Caledonia',
    },
    phone: '+687',
    regexZip: /^988\d{2}$/,
    zipFormat: '988NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 98800 - 98890.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ne.png`,
    code: 'NE',
    label: { en: 'Niger', de: 'Niger', it: 'Niger', fr: 'Niger', es: 'Niger' },
    phone: '+227',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/nf.png`,
    code: 'NF',
    label: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
      it: 'Norfolk Island',
      fr: 'Norfolk Island',
      es: 'Norfolk Island',
    },
    phone: '+672',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'Part of the Australian postal code system.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ng.png`,
    code: 'NG',
    label: {
      en: 'Nigeria',
      de: 'Nigeria',
      it: 'Nigeria',
      fr: 'Nigeria',
      es: 'Nigeria',
    },
    phone: '+234',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ni.png`,
    code: 'NI',
    label: {
      en: 'Nicaragua',
      de: 'Nicaragua',
      it: 'Nicaragua',
      fr: 'Nicaragua',
      es: 'Nicaragua',
    },
    phone: '+505',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/nl.png`,
    code: 'NL',
    label: {
      en: 'Netherlands',
      de: 'Niederlande',
      it: 'Netherlands',
      fr: 'Netherlands',
      es: 'Netherlands',
    },
    phone: '+31',
    regexZip: /^\d{4}\s{0,1}[A-Za-z]{2}$/,
    zipFormat: 'NNNN AA',
    zipNote:
      'The combination of the postcode and the housenumber gives a unique identifier of the address.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/no.png`,
    code: 'NO',
    label: {
      en: 'Norway',
      de: 'Norwegen',
      it: 'Norway',
      fr: 'Norway',
      es: 'Norway',
    },
    phone: '+47',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: 'From south to north',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/np.png`,
    code: 'NP',
    label: { en: 'Nepal', de: 'Nepal', it: 'Nepal', fr: 'Nepal', es: 'Nepal' },
    phone: '+977',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/nr.png`,
    code: 'NR',
    label: { en: 'Nauru', de: 'Nauru', it: 'Nauru', fr: 'Nauru', es: 'Nauru' },
    phone: '+674',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/nu.png`,
    code: 'NU',
    label: { en: 'Niue', de: 'Niue', it: 'Niue', fr: 'Niue', es: 'Niue' },
    phone: '+683',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/nz.png`,
    code: 'NZ',
    label: {
      en: 'New Zealand',
      de: 'Neuseeland',
      it: 'New Zealand',
      fr: 'New Zealand',
      es: 'New Zealand',
    },
    phone: '+64',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'Postcodes were originally intended for bulk mailing and were not needed for addressing individual items. However, new post codes for general use were phased in from June 2006 and came into force by July 2008.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/om.png`,
    code: 'OM',
    label: { en: 'Oman', de: 'Oman', it: 'Oman', fr: 'Oman', es: 'Oman' },
    phone: '+968',
    regexZip: /^\d{3}$/,
    zipFormat: 'NNN',
    zipNote: 'Deliveries to P.O. Boxes only.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pa.png`,
    code: 'PA',
    label: {
      en: 'Panama',
      de: 'Panama',
      it: 'Panama',
      fr: 'Panama',
      es: 'Panama',
    },
    phone: '+507',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pe.png`,
    code: 'PE',
    label: { en: 'Peru', de: 'Peru', it: 'Peru', fr: 'Peru', es: 'Peru' },
    phone: '+51',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pf.png`,
    code: 'PF',
    label: {
      en: 'French Polynesia',
      de: 'Französisch-Polynesien',
      it: 'French Polynesia',
      fr: 'French Polynesia',
      es: 'French Polynesia',
    },
    phone: '+689',
    regexZip: /^987\d{2}$/,
    zipFormat: '987NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 98700 - 98790.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pg.png`,
    code: 'PG',
    label: {
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea',
      it: 'Papua New Guinea',
      fr: 'Papua New Guinea',
      es: 'Papua New Guinea',
    },
    phone: '+675',
    regexZip: /^\d{3}$/,
    zipFormat: 'NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ph.png`,
    code: 'PH',
    label: {
      en: 'Philippines',
      de: 'Philippinen',
      it: 'Philippines',
      fr: 'Philippines',
      es: 'Philippines',
    },
    phone: '+63',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pk.png`,
    code: 'PK',
    label: {
      en: 'Pakistan',
      de: 'Pakistan',
      it: 'Pakistan',
      fr: 'Pakistan',
      es: 'Pakistan',
    },
    phone: '+92',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'Pakistan postal codes list',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pl.png`,
    code: 'PL',
    label: {
      en: 'Poland',
      de: 'Polen',
      it: 'Poland',
      fr: 'Poland',
      es: 'Poland',
    },
    phone: '+48',
    regexZip: /^[0-9]{2}-[0-9]{3}/,
    zipFormat: 'NN-NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pm.png`,
    code: 'PM',
    label: {
      en: 'Saint Pierre and Miquelon',
      de: 'St. Pierre und Miquelon',
      it: 'Saint Pierre and Miquelon',
      fr: 'Saint Pierre and Miquelon',
      es: 'Saint Pierre and Miquelon',
    },
    phone: '+508',
    regexZip: /^97500$/,
    zipFormat: '97500',
    zipNote: 'Overseas Collectivity of France. French codes used.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pn.png`,
    code: 'PN',
    label: {
      en: 'Pitcairn',
      de: 'Pitcairn',
      it: 'Pitcairn',
      fr: 'Pitcairn',
      es: 'Pitcairn',
    },
    phone: '+870',
    regexZip: /^[Pp][Cc][Rr][Nn]\s{0,1}[1][Zz]{2}$/,
    zipFormat: 'AAAANAA one code: PCRN 1ZZ',
    zipNote: 'UK territory, but not UK postcode',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pr.png`,
    code: 'PR',
    label: {
      en: 'Puerto Rico',
      de: 'Puerto Rico',
      it: 'Puerto Rico',
      fr: 'Puerto Rico',
      es: 'Puerto Rico',
    },
    phone: '+1',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pt.png`,
    code: 'PT',
    label: {
      en: 'Portugal',
      de: 'Portugal',
      it: 'Portugal',
      fr: 'Portugal',
      es: 'Portugal',
    },
    phone: '+351',
    regexZip: /^[0-9]{4}-[0-9]{3}/,
    zipFormat: 'NNNN-NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/pw.png`,
    code: 'PW',
    label: { en: 'Palau', de: 'Palau', it: 'Palau', fr: 'Palau', es: 'Palau' },
    phone: '+680',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'U.S. ZIP codes. All locations 96940.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/py.png`,
    code: 'PY',
    label: {
      en: 'Paraguay',
      de: 'Paraguay',
      it: 'Paraguay',
      fr: 'Paraguay',
      es: 'Paraguay',
    },
    phone: '+595',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/qa.png`,
    code: 'QA',
    label: { en: 'Qatar', de: 'Katar', it: 'Qatar', fr: 'Qatar', es: 'Qatar' },
    phone: '+974',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/re.png`,
    code: 'RE',
    label: {
      en: 'Reunion',
      de: 'Réunion',
      it: 'Reunion',
      fr: 'Reunion',
      es: 'Reunion',
    },
    phone: '+262',
    regexZip: /^974\d{2}$/,
    zipFormat: '974NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 97400 - 97490.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ro.png`,
    code: 'RO',
    label: {
      en: 'Romania',
      de: 'Rumänien',
      it: 'Romania',
      fr: 'Romania',
      es: 'Romania',
    },
    phone: '+40',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'Previously 99999 in Bucharest and 9999 in rest of country.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/rs.png`,
    code: 'RS',
    label: {
      en: 'Serbia',
      de: 'Serbien',
      it: 'Serbia',
      fr: 'Serbia',
      es: 'Serbia',
    },
    phone: '+381',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'Poštanski adresni kod (PAK)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ru.png`,
    code: 'RU',
    label: {
      en: 'Russian Federation',
      de: 'Russische Föderation',
      it: 'Russian Federation',
      fr: 'Russian Federation',
      es: 'Russian Federation',
    },
    phone: '+7',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'Placed on a line of its own.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sa.png`,
    code: 'SA',
    label: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien',
      it: 'Saudi Arabia',
      fr: 'Saudi Arabia',
      es: 'Saudi Arabia',
    },
    phone: '+966',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'A complete 13-digit code has 5-digit number representing region, sector, city, and zone; 4-digit X between 2000 and 5999; 4-digit Y between 6000 and 9999 [3]. Digits of 5-digit code may represent postal region, sector, branch, section, and block respectively [4].',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sb.png`,
    code: 'SB',
    label: {
      en: 'Solomon Islands',
      de: 'Salomonen',
      it: 'Solomon Islands',
      fr: 'Solomon Islands',
      es: 'Solomon Islands',
    },
    phone: '+677',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sc.png`,
    code: 'SC',
    label: {
      en: 'Seychelles',
      de: 'Seychellen',
      it: 'Seychelles',
      fr: 'Seychelles',
      es: 'Seychelles',
    },
    phone: '+248',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sd.png`,
    code: 'SD',
    label: { en: 'Sudan', de: 'Sudan', it: 'Sudan', fr: 'Sudan', es: 'Sudan' },
    phone: '+249',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/se.png`,
    code: 'SE',
    label: {
      en: 'Sweden',
      de: 'Schweden',
      it: 'Sweden',
      fr: 'Sweden',
      es: 'Sweden',
    },
    phone: '+46',
    regexZip: /^\d{3}s?d{2}/,
    zipFormat: 'NNN NN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sg.png`,
    code: 'SG',
    label: {
      en: 'Singapore',
      de: 'Singapur',
      it: 'Singapore',
      fr: 'Singapore',
      es: 'Singapore',
    },
    phone: '+65',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sh.png`,
    code: 'SH',
    label: {
      en: 'Saint Helena',
      de: 'St. Helena, Ascension und Tristan',
      it: 'Saint Helena',
      fr: 'Saint Helena',
      es: 'Saint Helena',
    },
    phone: '+290',
    regexZip: /^[Ss][Tt][Hh][Ll]\s{0,1}[1][Zz]{2}$/,
    zipFormat: 'STHL 1ZZ',
    zipNote: 'Single code used for all addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/si.png`,
    code: 'SI',
    label: {
      en: 'Slovenia',
      de: 'Slowenien',
      it: 'Slovenia',
      fr: 'Slovenia',
      es: 'Slovenia',
    },
    phone: '+386',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sk.png`,
    code: 'SK',
    label: {
      en: 'Slovakia',
      de: 'Slowakei',
      it: 'Slovakia',
      fr: 'Slovakia',
      es: 'Slovakia',
    },
    phone: '+421',
    regexZip: /^\d{3}s?d{2}/,
    zipFormat: 'NNN NN',
    zipNote:
      'with Czech Republic from west to east, Poštové smerovacie číslo (PSČ) - postal routing number.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sl.png`,
    code: 'SL',
    label: {
      en: 'Sierra Leone',
      de: 'Sierra Leone',
      it: 'Sierra Leone',
      fr: 'Sierra Leone',
      es: 'Sierra Leone',
    },
    phone: '+232',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sm.png`,
    code: 'SM',
    label: {
      en: 'San Marino',
      de: 'San Marino',
      it: 'San Marino',
      fr: 'San Marino',
      es: 'San Marino',
    },
    phone: '+378',
    regexZip: /^4789\d$/,
    zipFormat: '4789N',
    zipNote:
      'With Italy, uses a five-digit numeric CAP of Emilia Romagna. Range 47890 and 47899',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sn.png`,
    code: 'SN',
    label: {
      en: 'Senegal',
      de: 'Senegal',
      it: 'Senegal',
      fr: 'Senegal',
      es: 'Senegal',
    },
    phone: '+221',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The letters CP or C.P. are often written in front of the postcode. This is not a country code, but simply an abbreviation for "code postal".',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/so.png`,
    code: 'SO',
    label: {
      en: 'Somalia',
      de: 'Somalia',
      it: 'Somalia',
      fr: 'Somalia',
      es: 'Somalia',
    },
    phone: '+252',
    regexZip: null,
    zipFormat: null,
    zipNote: 'A 5 digit code has been publicized, but never taken into use.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/st.png`,
    code: 'ST',
    label: {
      en: 'Sao Tome and Principe',
      de: 'São Tomé und Príncipe',
      it: 'Sao Tome and Principe',
      fr: 'Sao Tome and Principe',
      es: 'Sao Tome and Principe',
    },
    phone: '+239',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sv.png`,
    code: 'SV',
    label: {
      en: 'El Salvador',
      de: 'El Salvador',
      it: 'El Salvador',
      fr: 'El Salvador',
      es: 'El Salvador',
    },
    phone: '+503',
    regexZip: /^1101$/,
    zipFormat: '1101',
    zipNote:
      'Used for all inbound mail to El Salvador. The postal office then distributes the mail internally depending on their destination.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sx.png`,
    code: 'SX',
    label: {
      en: 'Sint Maarten (Dutch part)',
      de: 'St. Martin (niederländischer Teil)',
      it: 'Sint Maarten (Dutch part)',
      fr: 'Sint Maarten (Dutch part)',
      es: 'Sint Maarten (Dutch part)',
    },
    phone: '+1-721',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sy.png`,
    code: 'SY',
    label: {
      en: 'Syrian Arab Republic',
      de: 'Arabische Republik Syrien',
      it: 'Syrian Arab Republic',
      fr: 'Syrian Arab Republic',
      es: 'Syrian Arab Republic',
    },
    phone: '+963',
    regexZip: null,
    zipFormat: null,
    zipNote: 'A 4-digit system has been announced. Status unknown.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/sz.png`,
    code: 'SZ',
    label: {
      en: 'Swaziland',
      de: 'Swasiland',
      it: 'Swaziland',
      fr: 'Swaziland',
      es: 'Swaziland',
    },
    phone: '+268',
    regexZip: /^[A-Za-z]\d{3}$/,
    zipFormat: 'ANNN',
    zipNote: "The letter identifies one of the country's four districts.",
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tc.png`,
    code: 'TC',
    label: {
      en: 'Turks and Caicos Islands',
      de: 'Turks- und Caicosinseln',
      it: 'Turks and Caicos Islands',
      fr: 'Turks and Caicos Islands',
      es: 'Turks and Caicos Islands',
    },
    phone: '+1-649',
    regexZip: /^[Tt][Kk][Cc][Aa]\s{0,1}[1][Zz]{2}$/,
    zipFormat: 'TKCA 1ZZ',
    zipNote: 'Single code used for all addresses.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/td.png`,
    code: 'TD',
    label: { en: 'Chad', de: 'Tschad', it: 'Chad', fr: 'Chad', es: 'Chad' },
    phone: '+235',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tg.png`,
    code: 'TG',
    label: { en: 'Togo', de: 'Togo', it: 'Togo', fr: 'Togo', es: 'Togo' },
    phone: '+228',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/th.png`,
    code: 'TH',
    label: {
      en: 'Thailand',
      de: 'Thailand',
      it: 'Thailand',
      fr: 'Thailand',
      es: 'Thailand',
    },
    phone: '+66',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The first two specify the province, numbers as in ISO 3166-2:TH, the third and fourth digits specify a district (amphoe)',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tj.png`,
    code: 'TJ',
    label: {
      en: 'Tajikistan',
      de: 'Tadschikistan',
      it: 'Tajikistan',
      fr: 'Tajikistan',
      es: 'Tajikistan',
    },
    phone: '+992',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'Retained system from former Soviet Union.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tk.png`,
    code: 'TK',
    label: {
      en: 'Tokelau',
      de: 'Tokelau',
      it: 'Tokelau',
      fr: 'Tokelau',
      es: 'Tokelau',
    },
    phone: '+690',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tl.png`,
    code: 'TL',
    label: {
      en: 'Timor-Leste',
      de: 'Timor-Leste',
      it: 'Timor-Leste',
      fr: 'Timor-Leste',
      es: 'Timor-Leste',
    },
    phone: '+670',
    regexZip: null,
    zipFormat: null,
    zipNote:
      'No postal code system in use since Indonesian withdrawal in 1999.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tm.png`,
    code: 'TM',
    label: {
      en: 'Turkmenistan',
      de: 'Turkmenistan',
      it: 'Turkmenistan',
      fr: 'Turkmenistan',
      es: 'Turkmenistan',
    },
    phone: '+993',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'Retained system from former Soviet Union.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tn.png`,
    code: 'TN',
    label: {
      en: 'Tunisia',
      de: 'Tunesien',
      it: 'Tunisia',
      fr: 'Tunisia',
      es: 'Tunisia',
    },
    phone: '+216',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/to.png`,
    code: 'TO',
    label: { en: 'Tonga', de: 'Tonga', it: 'Tonga', fr: 'Tonga', es: 'Tonga' },
    phone: '+676',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tr.png`,
    code: 'TR',
    label: {
      en: 'Turkey',
      de: 'Türkei',
      it: 'Turkey',
      fr: 'Turkey',
      es: 'Turkey',
    },
    phone: '+90',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'First two digits are the city numbers.[8]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tt.png`,
    code: 'TT',
    label: {
      en: 'Trinidad and Tobago',
      de: 'Trinidad und Tobago',
      it: 'Trinidad and Tobago',
      fr: 'Trinidad and Tobago',
      es: 'Trinidad and Tobago',
    },
    phone: '+1-868',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote:
      'First two digits specify a postal district (one of 72), next two digits a carrier route, last two digits a building or zone along that route',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tv.png`,
    code: 'TV',
    label: {
      en: 'Tuvalu',
      de: 'Tuvalu',
      it: 'Tuvalu',
      fr: 'Tuvalu',
      es: 'Tuvalu',
    },
    phone: '+688',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tw.png`,
    code: 'TW',
    label: {
      en: 'Taiwan, Province of China',
      de: 'Taiwan',
      it: 'Taiwan, Province of China',
      fr: 'Taiwan, Province of China',
      es: 'Taiwan, Province of China',
    },
    phone: '+886',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'The first three digits of the postal code are required; the last two digits are optional. Codes are known as youdi quhao (郵遞區號), and are also assigned to Senkaku Islands (Diaoyutai), though Japanese-administered, the Pratas Islands and the Spratly Islands. See List of postal codes in Taiwan.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/tz.png`,
    code: 'TZ',
    label: {
      en: 'United Republic of Tanzania',
      de: 'Vereinigte Republik Tansania',
      it: 'United Republic of Tanzania',
      fr: 'United Republic of Tanzania',
      es: 'United Republic of Tanzania',
    },
    phone: '+255',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ua.png`,
    code: 'UA',
    label: {
      en: 'Ukraine',
      de: 'Ukraine',
      it: 'Ukraine',
      fr: 'Ukraine',
      es: 'Ukraine',
    },
    phone: '+380',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ug.png`,
    code: 'UG',
    label: {
      en: 'Uganda',
      de: 'Uganda',
      it: 'Uganda',
      fr: 'Uganda',
      es: 'Uganda',
    },
    phone: '+256',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/us.png`,
    code: 'US',
    label: {
      en: 'United States',
      de: 'Vereinigte Staaten',
      it: 'United States',
      fr: 'United States',
      es: 'United States',
    },
    phone: '+1',
    regexZip: /^\d{5}?$/,
    zipFormat: 'NNNNN',
    zipNote:
      'Known as the ZIP Code with five digits 99999* or the ZIP+4 Code with nine digits 99999-9999* (while the minimum requirement is the first five digits, the U.S. Postal Service encourages everyone to use all nine). Also used by the former US Pacific Territories: Federated States of Micronesia; Palau; and the Marshall Islands, as well as in current US territories American Samoa, Guam, Northern Mariana Islands, Puerto Rico, and the United States Virgin Islands. An individual delivery point may be represented as an 11-digit number, but these are usually represented by Intelligent Mail barcode or formerly POSTNET bar code.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/uy.png`,
    code: 'UY',
    label: {
      en: 'Uruguay',
      de: 'Uruguay',
      it: 'Uruguay',
      fr: 'Uruguay',
      es: 'Uruguay',
    },
    phone: '+598',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/uz.png`,
    code: 'UZ',
    label: {
      en: 'Uzbekistan',
      de: 'Usbekistan',
      it: 'Uzbekistan',
      fr: 'Uzbekistan',
      es: 'Uzbekistan',
    },
    phone: '+998',
    regexZip: /^\d{3} \d{3}$/,
    zipFormat: 'NNN NNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/va.png`,
    code: 'VA',
    label: {
      en: 'Holy See (Vatican City State)',
      de: 'Vatikanstadt',
      it: 'Holy See (Vatican City State)',
      fr: 'Holy See (Vatican City State)',
      es: 'Holy See (Vatican City State)',
    },
    phone: '+379',
    regexZip: /^120$/,
    zipFormat: '120',
    zipNote:
      'Single code used for all addresses. Part of the Italian postal code system.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/vc.png`,
    code: 'VC',
    label: {
      en: 'Saint Vincent and the Grenadines',
      de: 'St. Vincent und die Grenadinen',
      it: 'Saint Vincent and the Grenadines',
      fr: 'Saint Vincent and the Grenadines',
      es: 'Saint Vincent and the Grenadines',
    },
    phone: '+1-784',
    regexZip: /^[Vv][Cc]\d{4}$/,
    zipFormat: 'CCNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ve.png`,
    code: 'VE',
    label: {
      en: 'Venezuela',
      de: 'Bolivarische Republik',
      it: 'Venezuela',
      fr: 'Venezuela',
      es: 'Venezuela',
    },
    phone: '+58',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/vg.png`,
    code: 'VG',
    label: {
      en: 'British Virgin Islands',
      de: 'Britische Jungferninseln',
      it: 'British Virgin Islands',
      fr: 'British Virgin Islands',
      es: 'British Virgin Islands',
    },
    phone: '+1-284',
    regexZip: /^[Vv][Gg]\d{4}$/,
    zipFormat: 'CCNNNN',
    zipNote: 'Specifically, VG1110 through VG1160[1]',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/vi.png`,
    code: 'VI',
    label: {
      en: 'US Virgin Islands',
      de: 'Amerikanische Jungferninseln',
      it: 'US Virgin Islands',
      fr: 'US Virgin Islands',
      es: 'US Virgin Islands',
    },
    phone: '+1-340',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: 'U.S. ZIP codes. Range 00801 - 00851.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/vn.png`,
    code: 'VN',
    label: {
      en: 'Vietnam',
      de: 'Vietnam',
      it: 'Vietnam',
      fr: 'Vietnam',
      es: 'Vietnam',
    },
    phone: '+84',
    regexZip: /^\d{6}$/,
    zipFormat: 'NNNNNN',
    zipNote: 'First two indicate a province.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/vu.png`,
    code: 'VU',
    label: {
      en: 'Vanuatu',
      de: 'Vanuatu',
      it: 'Vanuatu',
      fr: 'Vanuatu',
      es: 'Vanuatu',
    },
    phone: '+678',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/wf.png`,
    code: 'WF',
    label: {
      en: 'Wallis and Futuna',
      de: 'Wallis und Futuna',
      it: 'Wallis and Futuna',
      fr: 'Wallis and Futuna',
      es: 'Wallis and Futuna',
    },
    phone: '+681',
    regexZip: /^986\d{2}$/,
    zipFormat: '986NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 98600 - 98690.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/xk.png`,
    code: 'XK',
    label: {
      en: 'Kosovo',
      de: 'Kosovo',
      it: 'Kosovo',
      fr: 'Kosovo',
      es: 'Kosovo',
    },
    phone: '+383',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote:
      'A separate postal code for Kosovo was introduced by the UNMIK postal administration in 2004. Serbian postcodes are still widely used in the Serbian enclaves. No country code has been assigned.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/ye.png`,
    code: 'YE',
    label: { en: 'Yemen', de: 'Jemen', it: 'Yemen', fr: 'Yemen', es: 'Yemen' },
    phone: '+967',
    regexZip: null,
    zipFormat: null,
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/yt.png`,
    code: 'YT',
    label: {
      en: 'Mayotte',
      de: 'Mayotte',
      it: 'Mayotte',
      fr: 'Mayotte',
      es: 'Mayotte',
    },
    phone: '+262',
    regexZip: /^976\d{2}$/,
    zipFormat: '976NN',
    zipNote:
      'Overseas Department of France. French codes used. Range 97600 - 97690.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/za.png`,
    code: 'ZA',
    label: {
      en: 'South Africa',
      de: 'Südafrika',
      it: 'South Africa',
      fr: 'South Africa',
      es: 'South Africa',
    },
    phone: '+27',
    regexZip: /^\d{4}$/,
    zipFormat: 'NNNN',
    zipNote:
      'Postal codes are allocated to individual Post Office branches, some have two codes to differentiate between P.O. Boxes and street delivery addresses. Included Namibia (ranges 9000-9299) until 1992, no longer used.',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/zm.png`,
    code: 'ZM',
    label: {
      en: 'Zambia',
      de: 'Sambia',
      it: 'Zambia',
      fr: 'Zambia',
      es: 'Zambia',
    },
    phone: '+260',
    regexZip: /^\d{5}$/,
    zipFormat: 'NNNNN',
    zipNote: '',
  },
  {
    flag: `${env.NEXT_PUBLIC_BASE_PATH}/flags/zw.png`,
    code: 'ZW',
    label: {
      en: 'Zimbabwe',
      de: 'Simbabwe',
      it: 'Zimbabwe',
      fr: 'Zimbabwe',
      es: 'Zimbabwe',
    },
    phone: '+263',
    regexZip: null,
    zipFormat: null,
    zipNote: 'System is being planned.',
  },
]

export const getCountryByCode = (code?: string) => {
  return Countries.find((country) => country.code === code)
}
