import axios from 'axios'
import { PROXY_URL } from 'src/constants/Constants'
import { Endpoints } from 'src/constants/Endpoints'
import { env } from 'src/env.mjs'
import { Partner } from '../types/BackendData'
import { fetchWrapper } from './Utils'

export interface IpStack {
  ip: string
  type: string
  continent_code: string
  continent_name: string
  country_code: string
  country_name: string
  region_code: string
  region_name: string
  city: string
  zip: string
  latitude: number
  longitude: number
  location: {
    geoname_id: number
    capital: string
    languages: {
      code: string
      name: string
      native: string
    }[]
    country_flag: string
    country_flag_emoji: string
    country_flag_emoji_unicode: string
    calling_code: string
    is_eu: boolean
  }
}

export const getIpStack = async () => {
  const { data } = await axios.get<IpStack>(
    `${env.NEXT_PUBLIC_SIMO_API_URL}/ipstack`,
  )
  return data
}

interface GetPartnersResponse {
  q: string
  fetch_duration: number
  total_rows: number
  limit: number
  search_duration: number
  etag: string
  skip: number
  rows: {
    score: number
    doc: Partner
    id: string
  }[]
}

interface getPartnersOpts {
  countryCode: string
}

export const getPartners = async ({
  countryCode,
}: getPartnersOpts): Promise<Partner[]> => {
  const data = await fetchWrapper<GetPartnersResponse>({
    url: Endpoints.partnerByCountry,
    method: 'get',
    params: {
      country: countryCode,
    },
  })

  return data.rows.map((row) => row.doc)
}

export type EmailConflictType = 'EKP' | 'SiMo Classic'

export interface EmailStatus {
  exist: boolean
  application?: EmailConflictType
  status?: string
  whitelist: boolean
}

export const getEmailStatus = async (email: string) => {
  const { data } = await axios.post<EmailStatus>(PROXY_URL, {
    url: `${env.NEXT_PUBLIC_SIMO_API_URL}/user/exist`,
    method: 'post',
    data: { email },
  })
  return data
}

export const isEmailFree = (email: string) =>
  getEmailStatus(email).then((status) => !status.exist)

interface RegisterDto {
  firstname: string
  lastname: string
  email: string
  phone: string
  dealer: string
  country: string
  password: string
  role: string
}

export const register = async ({ dto }: { dto: RegisterDto }) => {
  const { data } = await axios.post(PROXY_URL, {
    url: `${env.NEXT_PUBLIC_SIMO_API_URL}/register`,
    method: 'post',
    data: dto,
  })

  return data
}
