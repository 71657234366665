import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { EmUrls } from '../../constants/EmUrls'
import { CustomerState, ValueOf } from '../../types'
import { Partner } from '../../types/BackendData'

export interface EmState {
  happyFlow: boolean
  isLoggedIn: boolean
  serialNumber: string
  materialNumber: string
  materialCode: string
  materialName: string
  equipmentId: string
  lowEquipmentSerialNumber: string
  lowEquipmentMaterialNumber: string
  customer?: CustomerState
  partner?: Partner
  correctionMode: boolean
  placeId?: string
  hasConfirmedGoogleSuggest: boolean
  visitedRoutes: ValueOf<typeof EmUrls>['logEntry'][]
  manualSerialNumber: string
  manualMaterialNumber: string
  manualMaterialCode: string
  zipCodeCallSuccess?: boolean
  deviceId?: string
  manualSelectedPartner?: Partner
  isSubmitted: boolean
}

type Action =
  | { type: 'UPDATE_EM_STATE'; payload: Partial<EmState> }
  | { type: 'UPDATE_CUSTOMER'; payload: Partial<EmState['customer']> }
  | { type: 'REMOVE_CUSTOMER_GOOGLE_ID' }

interface EmContext {
  emState: EmState
  dispatch: (action: Action) => void
}

export const defaultState: EmState = {
  isSubmitted: false,
  materialCode: '',
  manualMaterialNumber: '',
  manualSerialNumber: '',
  manualMaterialCode: '',
  equipmentId: '',
  lowEquipmentMaterialNumber: '',
  lowEquipmentSerialNumber: '',
  happyFlow: false,
  isLoggedIn: false,
  serialNumber: '',
  materialNumber: '',
  materialName: '',
  hasConfirmedGoogleSuggest: false,
  correctionMode: false,
  visitedRoutes: [],
}

const reducer = (state: EmState, action: Action): EmState => {
  switch (action.type) {
    case 'UPDATE_EM_STATE':
      return { ...state, ...action.payload }
    case 'REMOVE_CUSTOMER_GOOGLE_ID':
      const { google_id, ...customerRest } = state.customer!
      return { ...state, customer: customerRest }
    case 'UPDATE_CUSTOMER':
      const customer = { ...state.customer, ...action.payload }
      return { ...state, customer }
    default:
      throw new Error(`Unsupported action in EmContext reducer`)
  }
}

const EmContext = createContext<EmContext>({
  dispatch: () => {},
  emState: defaultState,
})

export const EmContextProvider = ({
  children,
  value = defaultState,
}: {
  children: React.ReactNode
  value?: EmState
}) => {
  const [emState, dispatch] = useReducer(reducer, value)
  return (
    <EmContext.Provider value={{ emState, dispatch }}>
      {children}
    </EmContext.Provider>
  )
}

export const useEmContext = () => {
  const context = useContext(EmContext)
  if (!context) {
    throw new Error('context must be used within a Provider')
  }
  return context
}
