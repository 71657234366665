import React from 'react'
import { makeStyles } from '@mui/styles'
import { env } from 'src/env.mjs'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    position: 'fixed',
    zIndex: Number.MAX_SAFE_INTEGER,
    margin: 'auto',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(181, 181, 181, 0.3)',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
  },
  image: {
    margin: 'auto',
  },
}))

const FullPageLoader = () => {
  const classes = useStyles()
  return (
    <span className={classes.container}>
      <img
        className={classes.image}
        src={`${env.NEXT_PUBLIC_BASE_PATH}/spinner.gif`}
        alt="loading"
      />
    </span>
  )
}

export default FullPageLoader
