import React from 'react'
import LanguageToggle from '../LanguageToggle'
import RegularLink from '../RegularLink'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  divider: {
    height: '1.3em',
    width: '2px',
    background: '#58535b',
    margin: '0 1em 0 0',
  },
  leftHalf: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '2em',
    alignItems: 'center',
  },

  rightHalf: {
    display: 'flex',
    height: '2em',
    alignItems: 'center',
  },

  container: {
    backgroundColor: 'rgba(231, 231, 231, 1)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}))

const PreHeader = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.leftHalf}>
        <RegularLink
          text={'Dentsply Sirona'}
          href="https://www.dentsplysirona.com/"
        />
        <div className={classes.divider} />
        <LanguageToggle />
      </div>
    </div>
  )
}

export default PreHeader
