import React from 'react'
import styles from './index.module.css'
import useI18n from '../../lib/hooks/useI18n'
import Links from './Links'
import { env } from 'src/env.mjs'

const Footer = () => {
  const { i18n, locale } = useI18n()
  return (
    <div className={styles['container']}>
      <div className={styles['left-half']}>
        <p className={styles['white-text']}>{i18n.rightsReserved}</p>
        {Links.map((link, idx) => (
          <a
            key={idx}
            href={link[locale]}
            target="_blank"
            rel="noreferrer"
            className={`${styles['white-text']} ${styles['link']}`}
          >
            {i18n[link.label]}
          </a>
        ))}
      </div>

      <div className={styles['right-half']}>
        <a
          href="https://twitter.com/dentsplysirona"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt=""
            className={styles['social-icon']}
            src={`${env.NEXT_PUBLIC_BASE_PATH}/images/Icon-Footer-Twitter-Dentsply-36x30px.png`}
          />
        </a>
        <a
          href="https://www.facebook.com/dentsplysirona.de/?brand_redir=320251664682553"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt=""
            className={styles['social-icon']}
            src={`${env.NEXT_PUBLIC_BASE_PATH}/images/Icon-Footer-Facebook-Dentsply-20x30px.png`}
          />
        </a>
      </div>
    </div>
  )
}

export default Footer
