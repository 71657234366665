import EN from "./en";

const FR = {
  en: "English",
  de: "Deutsch",
  it: "Italiano",
  fr: "Français",
  es: "Español",
  language: "Français",
  help: "Aide",
  newHere: "Nouveau? ",
  createAccount: "Créer un compte",
  forgotPassword: "J'ai oublié mon mot de passe",
  rememberMe: "Se rappeler de moi",
  siroBlue: "Siroforce Mobile",
  signIn: "Se connecter",
  faq: "FAQ/Retour/Support",
  mobileOverview: "Aperçu de Siroforce Mobile ",
  siroforceMobile: "Accès à Siroforce Mobile",
  deviceRegistration: "Enregistrement de l'appareil",
  ticketing: "Demande de service / Ticket",
  preventiveMaintenance: "Maintenance préventive",
  marketAction: "Action marché",
  monitoring: "Évaluation et rapports",
  helpcenter: "Centre d'aide",
  welcome: "Bienvenu à Siroforce Mobile.",
  access: "Votre accès au support technique Dentsply Sirona.",
  email: " Adresse Email",
  password: "Mot de passe",
  rightsReserved: "© 2024 Dentsply Sirona. Tous Droits réservés.",
  privacy: "Mentions légales",
  termsOfUse: "Conditions d'utilisation",
  imprint: "Imprimer",
  commercialArea: "Zone commerciale",
  compliance: "Conformités & Déontologie",
  inc: "Dentsply Sirona Inc",
  instructions: "Instructions d'utilisations",
  register: "S'inscrire",
  emailFieldLabel: "Email (connexion)",
  provideValidEmail: "Merci de fournir une adresse email valide",
  provideValidPassword: "Merci de fournir un mot de passe valide",
  passwordsDoNotMatch: "Le mot de passe ne correspond pas",
  fieldRequired: "Le champ est requis",
  firstName: "Prénom",
  lastName: "Nom",
  phone: "Numéro de téléphone",
  noPartnersFound: "Aucun partenaire trouvé.",
  zipCode: "Code postal",
  chooseState: "Choisir un état",
  requiredPartner: "Un partenaire doit être selectionné!",
  myRole: "Mon rôle",
  technician: " Technicien de maintenance",
  sales: "Ventes",
  chooseCountry: "Choisir un pays",
  passwordRepeat: "répétition du mot de passe",
  emailTaken: "E-Mail déja utilisé",
  signUp: "S'inscrire",
  error: "Erreur",
  registeredInClassic:
    "Vous êtes déja inscrit à Siroforce Mobile. Merci de vous connecter",
  registeredInEKP: "Vous êtes déja enregistré à EKP. Merci de vous connecter",
  close: "Fermer",
  confirm: "Confirmer",
  none: "rien",
  registrationSuccess:
    "Merci pour votre inscription. Si vous ne recevez pas votre email de confirmation, merci de vérifier votre dossier indésirable. Seulement quand votre adresse email a été validée votre inscription sera terminée.",
  registrationReceived: "Demande d'enregistrement reçue",
  registrationFailed:
    "L'enregistrement a échoué, svp essayez plus tard, echec d'enregistrement",
  youAre: "Vous êtes?",
  endCustomer: "Client final",
  technicianSales: "Techniciens/Commercial",
  thirdParty: "Sous-traitant",
  check: "Envoyer",
  alreadyRegisteredEKPStart: "Un utilisateur avec votre adresse existe déjà",
  here: "ici",
  notRegisteredInEKP:
    "Il n'y a pas d'utilisateur encore. Enregistrez-vous svp.",
  alreadyExistingInTechnicianButEKP:
    "Un compte existe déjà avec votre adresse email sur le portail technicien.",
  wantToTransferToEKP:
    "Cependant, si vous souhaitez transférer votre compte sur le portail de réclamation client, merci d'envoyer un email à %s",
  alreadyRegisteredInClassic:
    "Un utilisateur avec votre adresse email existe déjà",
  notRegisteredInClassic:
    "Il n'y a pas d'utlisateur encore. Merci de vous enregistrer.",
  alreadyExistingInEKPButTechnician:
    "Il y a déja un compte avec votre adresse email dans le portail de réclamation client.",
  wantToTransferToClassic:
    "Cependant, si vous souhaitez transférer votre compte vers le prtail technicien, merci d'envoyer un email à %s",
  confirmHeading: "Merci de vérifier votre adresse email.",
  confirm1Body:
    "En règle générale, les revendeurs Dentsply Sirona reçoivent dans les minutes qui suivent un e-mail les informant de l'activation de Siroforce Mobile. Si vous ne recevez pas d'e-mail à court terme, votre enregistrement doit être vérifié par Dentsply Sirona. En règle générale, nous vous contacterons dans un délai de 1 à 2 jours ouvrables. ",
  confirm2Body:
    "Votre compte a été activé. Vous pouvez maintenant directement vous connecter dans Siroforce Mobile.",
  loginSiroforce: "Connexion Siroforce Mobile",
  successfullLogin: "Connecté avec succès!",
  errorLoggingIn: "Erreur de connexion",
  provideValidQueryParams:
    "Merci de fournir des paramètres de recherde valides.",
  verifyAssetInformation: "Vérifier les informations de l'équipement",
  assetFound:
    "Merci de vérifier les numéros de série et de référence mentionnés sur la plaque signalitique de l'appareil.",
  materialNumber: "REF / Model-No.",
  serialNumber: "SN / Serial-No.",
  pleaseConfirm: "Merci de confirmer",
  false: "Incorrect",
  unexpectedError: "Erreur inconnue, merci de recommencer plus tard",
  invalidToken:
    "Erreur.Verfier si votre jeton (token) est valide et non expiré puis re essayer.",
  assetNotFound:
    "Impossible de trouver votre équipement. Merci de sélectionner manuellement.",
  yourDevice: "Entrer les informations de l'appareil",
  enterMaterialAndSerial:
    "Merci de saisir les numéros de série et de référence mentionnés sur la plaque signalitique de l'appareil.",
  next: "Suivant",
  enterPostalCode: "Saisir code postal",
  authorizeByEnteringPostalCode:
    "Nous avons trouvé un enregistrement existant  pour votre équipement Dentsply Sirona. Merci de vérifier l'enregistrement existant par votre code postal.",
  postalCode: "Code postal",
  customerNotFound:
    "Nous n'avons pas trouvé le client.Merci de sélectionner manuellement.",
  checkContactInformation: "Verifier l'enregistrement",
  checkRelatedMasterData:
    "Merci de confirmer l'enregistrement existant avec Dentsply Sirona ou apporter les corrections nécessaires.",
  correction: "Incorrect",
  customerData: "Votre organisation",
  enterCustomerData:
    "Votre équipement n'est pas encore enregistré avec Dentsply Sirona. Merci de renseigner vos informations cliniques afin de terminer l'enregistrement.",
  name: "Nom",
  street: "Rue",
  city: "Ville",
  houseNumber: "Numéro",
  country: "Pays",
  state: "Etat",
  allowAddressOptimization: "Voulez vous optimiser vos informations postales?",
  reject: "Rejetter",
  sent: "Envoyer:",
  suggested: "Proposé:",
  selectDealer: "Votre distributeur",
  selectDealerWhichMaintainsDevice:
    "Merci de sélectionner votre distributeur préféré pour votre équipement.",
  foundTopEquipment: "Identification de votre équipement avec succés.",
  credentialsWentWrong:
    "Erreur lors de votre authentification. Merci d'utiliser votre lien URL avec un jeton (token) pour valider l'accès.",
  submitSuccess: "Merci! Votre enregistrement est maintenant terminé.",
  confirmDealer: "Vérifiez votre distributeur",
  checkRelatedDealer:
    "Merci de confirmer les informations de votre distributeur ou faites les corrections nécessaires.",
  identifyDevice: "Sélectionnez votre équipement",
  pleasePickDevice:
    "Nous avons besoin d'informations complémentaires pour votre équipemet. Merci de sélectionner la catégorie applicable à votre équipement pour continuer avec l'enregistrement.",
  select: "Selectionnez",
  deviceHasToBeSelected: "Un équipement doit être sélectionné!",
  dealerUnknown: "Distributeur inconnu",
  addMissingDealer: "Ajouter distributeur manquant",
  wrongPhoneFormat: "Format numéro de téléphone invalide",
  wrongZipFormat: "Format code postal invalide",
  welcomeToDentsply: "Bienvenu chez Dentsply Sirona!",
  welcomeImageText:
    "Ensemble, nous vous accompagnerons avec intégrité, intelligence, avec des solutions adaptées à vos besoins pour vous fournir le meilleure traitement et satisfaction client. Merci de terminer l'enregistrment de vos équipements aux étapes suivantes afin de vous servir de la meilleure des façons.",
  cerecPrimeScan: "CEREC Primescan",
  cerecOmniScan: "CEREC Omnicam",
  clinic: "Clinique",
  serviceCenter: "Centre de réparation",
  deviceSuccessfullyConnected:
    "Votre équipement est maintenant connecté avec succès au cloud. Merci pour votre confiance, nous continuerons à améliorer l'expérience client.",
  continue: "Continuez",
  authorize: "Autorisez",
  makeCorrections:
    "Merci d'apporter les corrections à vos informations cliniques en selectionnant les cases correspondantes.",
  postalCodeFail:
    "L'autorisation via code postal a échoué. Merci d'entrer vos informations cliniques pour terminer le processus d'enregistrement.",
  oops: "Oops!",
  somethingWentWrong: "Quelque chose a échoué.",
  sessionTimedOut:
    "Votre session a expiré. Cliquer sur le lien Verifier l'équipement en ligne dans l'écran système de l'application Dentsply Sirona pour recommencer le processus. Fermez le navigateur pour retourner à l'écran système.",
  invalidMaterialNumber:
    "Numéro de matériel invalide. Merci de le rentrer manuellement.",
  authorizedTechnicianSales: "Distributeur autorisé / Employé Dentsply Sirona",
  egDentist: "(e.g. dentiste, laboratoire, clinique)",
  egITService: "(e.g. Fournisseur de service Informatique)",
  yourEmailAddress: "Votre adresse email:",
  welcomeDentsplyProductService:
    "Bienvenu au service technique Dentsply Sirona",
  selectRole: "Merci de sélectionner votre rôle.",
  yes: "oui",
  redirectedToTechnicianPortal:
    "Vous allez être maintenant redirigé vers le portail technicien...",
  registerHere: "Cliquer ici pour vous enregistrer",
  redirectedToCustomerPortal:
    "Vous allez être maintenant redirigé vers le portail réclamation client...",
  asThirdPartyPleaseCall:
    "A titre d'un sous-traitant, merci de nous contacter par téléphone",
  accessToTheDentsply:
    "L'accès au portail de Service Dentsply Sirona est exclusivement réservé à nos clients, distributeurs agréés et employés Dentsply Sirona",
  pleaseContact:
    "Merci de contacter votre Distributeur Dentsply Sirona avec votre demande ou contacter le service technique Dentsply Sirona.",
  pleaseCallUs: "Merci de nous appeler.",
  findPhoneNumbers:
    "Merci de cliquer sur le lien pour votre recherche de numéro de téléphone: ",
  iAm: "Je suis...",
  loginTechnicianPortal: "Connexion pour technicien ou employé DS",
  loginComplaintPortal: "Connexion pour client",
  notOfficialDealerDomain:
    "Votre domaine email n’est pas enregistré comme domaine official distributeur. Une activation manuelle doit être faite et pourrait prendre quelques jours.",
  open: "Ouvrir",
  filter: "Filtre(rechercher par nom, ville ou code postal)",
  technicianPortal: "Portail des techniciens",
  confirmErrorBody: "Echec de la vérification de l'e-mail.",
  maintenanceHeading: "Wartungsmodus",
  maintenanceBody:
    "Es finden gerade Wartungsarbeiten für Siroforce statt. Wir sind bald wieder zurück.",
  feedbackHeader:
    "Merci d’avoir pris le temps de nous faire part de vos commentaires. Cela nous aidera à améliorer ce site pour tout le monde.",
    feedBackHint: "Ce formulaire de feedback est destiné à signaler des erreurs ou des éléments/sujets manquants sur ce site de support. L'équipe web ne peut pas fournir de service technique ni transmettre des demandes techniques à une équipe de service dans un pays lointain. Veuillez contacter votre revendeur local ou votre équipe de service pour toute question concernant les produits.",
  feedBackThankYou: "Merci de votre compréhension !",
  feedbackTitle: "Retour d’information",
  feedbackName: "Nom",
  feedbackSubmit: "Soumettre",
  feedbackEmail: "Courriel",
  feedbackDetails: "Votre avis",
  feedbackSubmitSuccess: "Merci pour vos commentaires !",
  feedbackSubmitError:
    "Il n’a pas été possible d’envoyer votre commentaire. Veuillez réessayer plus tard.",
  urlCopiedToClipboard: "L'URL a été copiée dans le presse-papiers.",

  // translate from here
  cancel: "Annuler",
  createDate: "Date de création",
  customer: "Client",
  description: "Description",
  device: "Appareil",
  dropFilesHere: "Déposer des fichiers ici",
  dropFilesOrSelect: "Déposer ou sélectionner des fichiers ici",
  fileTooLarge:
    "Le fichier est trop volumineux. Taille maximale du fichier 5 Mo",
  localRequestStatusAll: "Tous les",
  localRequestStatusClosed: "Terminé",
  localRequestStatusInProgress: "En cours",
  localRequestStatusOpen: "Ouvert",
  localRequestStatusPending: "En attente",
  messageSendError: "Le message n'a pas pu être envoyé",
  noteSendError: "Le message n'a pas pu être envoyé",
  only5FilesAllowed: "Seuls 5 fichiers sont autorisés",
  pleaseTypeYourMessage: "Veuillez saisir votre message",
  request: "Demande de service",
  requestStatusAdditionProvided: "En cours",
  requestStatusCancelled: "Annulé",
  requestStatusCompleted: "Terminé",
  requestStatusCustomerAction: "Dans l'attente de votre réponse",
  requestStatusForwarded: "En cours",
  requestStatusInProgress: "En cours",
  requestStatusSolutionProvided: "Solution fournie",
  status: "État",
  uploadAttachments: "Télécharger des pièces jointes",
  PleaseContactYourSupportTeam:
    "Veuillez contacter notre support d'application",
  ErrorCode: "Code d'erreur: ",
  pushNotification: "Notification poussée",
  INIT: "Initiée",
  AWDE: "En attente d'un appareil",
  REPA: "Réparation terminée",
  CANC: "Annulé",
  INSP: "Inspection",
  AWCA: "En attente de l'approbation du client",
  REST: "Réparation commencée",
  REDE: "Réparation refusée",
  CLOS: "Opération terminée",
  REAP: "Réparation approuvée",
  REPE: "En attente de l'approbation du client",
};

const withDefaults = { ...EN, ...FR };

export default withDefaults;
