import { env } from 'src/env.mjs'

export const PROXY_URL = `${env.NEXT_PUBLIC_API_URL}/proxy`

export const EKP_DEVEL_URL =
  'https://devel.dentsplysirona.service-pacemaker.com/'
export const EKP_PROD_URL = 'https://dentsplysirona.service-pacemaker.com/'
export const SIMO_PROD_URL = 'https://sirona.service-pacemaker.com/'
export const SIMO_DEVEL_URL = 'https://devel.sirona.service-pacemaker.com/'
export const SIMO_EMAIL = 'siroforcemobile@dentsplysirona.com'
