const Links = [
  {
    en: 'https://www.dentsplysirona.com/en/legal/terms-of-use.html',
    de: 'https://www.dentsplysirona.com/de-de/legal/nutzungsbedingungen.html',
    it: 'https://www.dentsplysirona.com/it-it/legal/termini-d-uso.html',
    fr: 'https://www.dentsplysirona.com/fr-fr/legal/termes-et-conditions.html',
    es: 'https://www.dentsplysirona.com/es-ib/legal/informacion-legal.html',
    label: 'termsOfUse',
  },
  {
    en: 'https://www.dentsplysirona.com/en/legal/privacy-policy.html',
    de: 'https://www.dentsplysirona.com/de-de/legal/datenschutzerklaerung.html',
    it: 'https://www.dentsplysirona.com/it-it/legal/privacy-policy.html',
    fr: 'https://www.dentsplysirona.com/fr-fr/legal/mentions-legales.html',
    es: 'https://www.dentsplysirona.com/es-ib/legal/terminos-y-condiciones.html',
    label: 'privacy',
  },
  {
    en: 'https://www.dentsplysirona.com/en/legal/compliance-ethics.html',
    de: 'https://www.dentsplysirona.com/de-de/company/our-company/compliance-ethics.html',
    it: 'https://www.dentsplysirona.com/it-it/legal/compliance-ed-etica.html',
    fr: 'https://www.dentsplysirona.com/fr-fr/a-propos-de-dentsply-sirona/conformites-deontologie.html',
    es: 'https://www.dentsplysirona.com/es-ib/legal/etica-cumplimiento.html',
    label: 'compliance',
  }
] as const

export default Links
