import { CountryState } from '../types'

const States: CountryState[] = [
  {
    label: 'Alberta',
    code: 'AB',
  },
  {
    label: 'British Columbia',
    code: 'BC',
  },
  {
    label: 'Manitoba',
    code: 'MB',
  },
  {
    label: 'New Brunswick',
    code: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    code: 'NL',
  },
  {
    label: 'Northwest Territories',
    code: 'NT',
  },
  {
    label: 'Nova Scotia',
    code: 'NS',
  },
  {
    label: 'Nunavut',
    code: 'NU',
  },
  {
    label: 'Ontario',
    code: 'ON',
  },
  {
    label: 'Prince Edward Island',
    code: 'PE',
  },
  {
    label: 'Quebec',
    code: 'QC',
  },
  {
    label: 'Saskatchewan',
    code: 'SK',
  },
  {
    label: 'Yukon Territory',
    code: 'YT',
  },
]
export default States
