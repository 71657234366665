import styles from './index.module.css'

interface Props {
  buttonLabel: string
  menuItems: {
    onClick: () => void
    name: string
  }[]
}

const HoverMenu = ({ buttonLabel, menuItems }: Props) => {
  return (
    <div className={styles['dropdown']}>
      <button className={styles['dropbtn']}>{buttonLabel}</button>
      <div className={styles['dropdown-content']}>
        {menuItems.map((menuItem, idx) => (
          <a key={idx} onClick={menuItem.onClick}>
            {menuItem.name}
          </a>
        ))}
      </div>
    </div>
  )
}

export default HoverMenu
