import HttpStatus from '../../constants/HttpStatus'
import { ValueOf } from '../../types'

interface ConstructorOpts {
  status: ValueOf<typeof HttpStatus>
  statusText: string
  message?: string
}

export default class FetchError extends Error {
  readonly status: ValueOf<typeof HttpStatus>
  readonly statusText: string

  constructor({ status, statusText, message }: ConstructorOpts) {
    super(message)
    Error.captureStackTrace(this, this.constructor)
    Object.setPrototypeOf(this, FetchError.prototype)
    this.status = status
    this.statusText = statusText
  }

  isFetchError() {
    return true
  }
}
