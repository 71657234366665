import EN from "./en";

const IT = {
  en: "English",
  de: "Deutsch",
  it: "Italiano",
  fr: "Français",
  es: "Español",
  language: "Italiano",
  help: "Aiuto",
  newHere: "Nuovo ?",
  createAccount: "Crea account",
  forgotPassword: "Ho dimenticato la password",
  rememberMe: "Ricordami",
  siroBlue: "Siroforce Mobile",
  signIn: "LOGIN",
  faq: "FAQ/Feedback/Supporto",
  mobileOverview: "Panoramica di Siroforce Mobile",
  siroforceMobile: "Accesso a Siroforce Mobile",
  deviceRegistration: "Registrazione del dispositivo",
  ticketing: "Richiesta di assistenza / Ticket",
  preventiveMaintenance: "Manutenzione preventiva",
  marketAction: "Market Action",
  monitoring: "Valutazione e reporting",
  helpcenter: "Centro assistenza",
  welcome: "Benvenuto in Siroforce Mobile.",
  access: "Il tuo accesso al supporto tecnico Dentsply Sirona.",
  email: "Indirizzo Email",
  password: "Password",
  rightsReserved: "© 2024 Dentsply Sirona. All rights reserved.",
  privacy: "Privacy Policy",
  termsOfUse: "Termini d'uso",
  imprint: "Imprint",
  commercialArea: "Area commerciale",
  compliance: "Compliance ed etica",
  inc: "Dentsply Sirona Inc",
  instructions: "Istruzioni per l'uso",
  register: "Registrazione",
  emailFieldLabel: "Email (Login)",
  provideValidEmail: "Per favore inserisci un'indirizzo email valido",
  provideValidPassword: "Per favore inserisci una password valida",
  passwordsDoNotMatch: "Le password non corrispondono",
  fieldRequired: "Valore obbligatorio",
  firstName: "Nome",
  lastName: "Cognome",
  phone: "Numero Telefono",
  noPartnersFound: "Nessun partners trovato.",
  zipCode: "CAP",
  chooseState: "Scegli uno stato",
  requiredPartner: "Devi selezionare un partner!",
  myRole: "Mio ruolo",
  technician: "Tecnico assistenza",
  sales: "Vendite",
  chooseCountry: "Scegli un paese",
  passwordRepeat: "ripeti la password",
  emailTaken: "E-mail già utilizzata",
  signUp: "Iscrizione",
  error: "ErrorE",
  registeredInClassic:
    "Sei già registrato in Siroforce Mobile. Per favore accedi",
  registeredInEKP: "Sei già registrato in EKP. Per favore accedi",
  close: "Chiude",
  confirm: "Conferma",
  none: "Nessuno",
  registrationSuccess:
    "Grazie per la tua registrazione.Se non ricevi la e-mail di conferma controlla anche tra la posta indesiderata o SPAM. Solo quando il tu oindirizzo e-mail è stato validato la registrazione sarà completa.",
  registrationReceived: "Richiesta di registrazione ricevuta",
  registrationFailed: "Registrazione fallita. Per favore riprova più tardi.",
  youAre: "Chi sei?",
  endCustomer: "Utente finale",
  technicianSales: "Tecnico / Vendite",
  thirdParty: "Terze parti",
  check: "Invia",
  alreadyRegisteredEKPStart:
    "Un utente con quest'indirizzo email è già presente",
  here: "qui",
  notRegisteredInEKP: "Utente non presente. Per favore registrati.",
  alreadyExistingInTechnicianButEKP:
    "Un utente con il tuo indirizzo email è già presente nel portale tecnici",
  wantToTransferToEKP:
    "Se desideri spostare il tuo account nel portale clienti, invia un'e-mail a %s",
  alreadyRegisteredInClassic:
    "Un utente con il tuo indirizzo email è già esistente",
  notRegisteredInClassic: "Utente non presente. Per favore registrati.",
  alreadyExistingInEKPButTechnician:
    "E' già presente un utente con il tuo indirizzo e-mail.",
  wantToTransferToClassic:
    "Se desideri spostare il tuo account nel portale tecnici, invia un'e-mail a %s",
  confirmHeading: "Grazie per aver verificato il tuo indirizzo email.",
  confirm1Body:
    "I rivenditori Dentsply Sirona di solito ricevono entro pochi minuti un'e-mail con l'attivazione per Siroforce Mobile. Se non ricevete un'e-mail a breve termine, la vostra registrazione deve essere controllata da Dentsply Sirona. Di solito vi risponderemo entro 1-2 giorni lavorativi. ",
  confirm2Body:
    "Il tuo account è stato attivato. Ora puoi accedere direttamente a Siroforce Mobile.",
  loginSiroforce: "Accedi Siroforce Mobile",
  successfullLogin: "Accesso effettuato con successo!",
  errorLoggingIn: "Errore d'accesso",
  provideValidQueryParams: "Fornisci parametri validi.",
  verifyAssetInformation: "Verifica informazioni dispositivo",
  assetFound:
    "Verificare il seguente numero di serie e di riferimento con quelli visualizzati sull'etichetta del prodotto sul retro del dispositivo.",
  materialNumber: "REF / Model-No.",
  serialNumber: "SN / Serial-No.",
  pleaseConfirm: "Per favore conferma",
  false: "Non corretto",
  unexpectedError: "Errore inatteso. Per favore riprova più tardi",
  invalidToken:
    "Errore. Controlla se il tuo token è valido e non è scaduto e riprova.",
  assetNotFound:
    "Impossibile trovare la tua attrezzatura. Si prega di selezionarla manualmente.",
  yourDevice: "Inserisci le informazioni sul dispositivo",
  enterMaterialAndSerial:
    "Immettere le informazioni sul dispositivo di serie e numero di riferimento visualizzati sull'etichetta del prodotto sul retro del dispositivo.",
  next: "Prossimo",
  enterPostalCode: "Inserisci CAP",
  authorizeByEnteringPostalCode:
    "Abbiamo trovato una registrazione esistente per il tuo dispositivo con Dentsply Sirona. Si prega di verificare la registrazione esistente inserendo il codice postale.",
  postalCode: "CAP",
  customerNotFound:
    "Impossibile trovare il cliente. Si prega di selezionarlo manualmente.",
  checkContactInformation: "Verifica registrazione",
  checkRelatedMasterData:
    "Conferma la tua registrazione esistente con Dentsply Sirona o apporta le correzioni appropriate.",
  correction: "Non corretto",
  customerData: "Tua organizzazione",
  enterCustomerData:
    "Il tuo dispositivo non è ancora registrato con Dentsply Sirona. Inserisci le informazioni del tuo studio per completare il processo di registrazione.",
  name: "Nome",
  street: "Indirizzo",
  city: "Città",
  houseNumber: "Numero",
  country: "Nazione",
  state: "Stato",
  allowAddressOptimization:
    "Vuoi che ottimizziamo le informazioni sul tuo indirizzo?",
  reject: "Rifiuta",
  sent: "Inviato:",
  suggested: "Suggerito:",
  selectDealer: "Il tuo partner",
  selectDealerWhichMaintainsDevice:
    "Seleziona il tuo partner di servizio preferito per il tuo dispositivo.",
  foundTopEquipment: "Attrezzatura identificata con successo.",
  credentialsWentWrong:
    "Qualcosa è andato storto con la tua autenticazione. Usa il tuo URL con il token per ottenere l'accesso.",
  submitSuccess: "Grazie! La tua registrazione è ora completa.",
  confirmDealer: "Verifica Service Partner",
  checkRelatedDealer:
    "Conferma le informazioni sul tuo partner di servizio o apporta le correzioni appropriate.",
  identifyDevice: "Seleziona il tuo dispositivo.",
  pleasePickDevice:
    "Abbiamo bisogno di alcune informazioni aggiuntive sul tuo dispositivo. Seleziona la categoria di prodotto applicabile per il tuo dispositivo per continuare con la registrazione.",
  select: "Seleziona",
  deviceHasToBeSelected: "Un dispositivo deve essere selezionato !",
  dealerUnknown: "Rivenditore sconosciuto",
  addMissingDealer: "Inserire rivenditore mancante",
  wrongPhoneFormat: "Formato telefono invalido",
  wrongZipFormat: "CAP invalido",
  welcomeToDentsply: "Benvenuto in Dentsply Sirona!",
  welcomeImageText:
    "Insieme a te guidiamo soluzioni e servizi integrati, intelligenti e incentrati sul cliente per fornire migliori risultati di trattamento e cura del paziente. Completa la registrazione del tuo dispositivo nei seguenti passaggi per consentirci di supportarti nel miglior modo possibile.",
  cerecPrimeScan: "CEREC Primescan",
  cerecOmniScan: "CEREC Omnicam",
  clinic: "Studio",
  serviceCenter: "Centro assistenza",
  deviceSuccessfullyConnected:
    "Il tuo dispositivo è ora connesso con successo. Grazie al tuo prezioso supporto, siamo in grado di migliorare continuamente l'esperienza del cliente.",
  continue: "Continua",
  authorize: "Autorizza",
  makeCorrections:
    "Si prega di apportare le correzioni appropriate alle informazioni sul proprio studio facendo clic nella rispettiva casella.",
  postalCodeFail:
    "L'autorizzazione tramite codice postale non è andata a buon fine. Inserisci le informazioni del tuo studio per completare il processo di registrazione.",
  oops: "Oops!",
  somethingWentWrong: "Qualcosa è andato storto,",
  sessionTimedOut:
    "La tua sessione è scaduta. Fare clic sul collegamento Verifica registrazione dispositivo online nell'applicazione Dentsply Sirona System Monitor per riavviare questo processo. Chiudi questa scheda del browser per tornare al Monitor di sistema.",
  invalidMaterialNumber:
    "Numero materiale non valido. Si prega di inserirlo manualmente.",
  authorizedTechnicianSales:
    "Rivenditore autorizzato <br> Dipendente Dentsply Sirona",
  egDentist: "(e.g. dentista, laboratorio, clinica)",
  egITService: "(e.g. Servizi IT )",
  yourEmailAddress: "Il tuo indirizzo di posta elettronica:",
  welcomeDentsplyProductService:
    "Benvenuto nel servizio prodotti Dentsply Sirona",
  selectRole: "Per favore scegli il tuo ruolo",
  yes: "Si",
  redirectedToTechnicianPortal:
    "Verrai ora reindirizzato al portale dei tecnici...",
  registerHere: "Clicca qui per registrarti. ",
  redirectedToCustomerPortal: "Verrai ora reindirizzato al portale clienti ...",
  asThirdPartyPleaseCall:
    "In qualità di fornitore di terze parti, contattaci telefonicamente.",
  accessToTheDentsply:
    "L'accesso ai portali di assistenza Dentsply Sirona è riservato esclusivamente ai clienti finali, ai rivenditori autorizzati e ai dipendenti Dentsply Sirona.",
  pleaseContact:
    "Si prega di contattare il rivenditore Dentsply Sirona responsabile o contattare il servizio prodotti Dentsply Sirona.",
  pleaseCallUs: "Oer favore chiamaci",
  findPhoneNumbers:
    "Fare clic su questo collegamento per cercare i numeri di telefono:",
  iAm: "Io sono ...",
  loginTechnicianPortal: "Login per tecnici / Dipendenti DS",
  loginComplaintPortal: "Login per clienti",
  notOfficialDealerDomain:
    "Il tuo dominio email non è attualmente registrato come dominio di un nostro rivenditore ufficiale. L'attivazione deve essere eseguita manualmente e potrebbe richiedere diversi giorni.",
  open: "Apri",
  filter: "Filtro",
  technicianPortal: "portale tecnici",
  confirmErrorBody: "Verifica dell'e-mail non riuscita.",
  maintenanceHeading: "Wartungsmodus",
  maintenanceBody:
    "Es finden gerade Wartungsarbeiten für Siroforce statt. Wir sind bald wieder zurück.",
  feedbackHeader:
    "Grazie per aver dedicato del tempo a fornire un feedback. Questo ci aiuterà a migliorare il sito per tutti.",
  feedBackHint:
    'Questo modulo di feedback è destinato a segnalare errori o elementi/argomenti mancanti su questo sito di supporto. Il team web non può fornire assistenza tecnica né inoltrare richieste tecniche a un team di assistenza in un paese lontano. Si prega di contattare il proprio rivenditore o team di assistenza locale per qualsiasi domanda sui prodotti.',
  feedBackThankYou: 'Grazie per la vostra comprensione!',
  feedbackTitle: "Feedback",
  feedbackName: "Nome",
  feedbackSubmit: "Invia",
  feedbackEmail: "Email",
  feedbackDetails: "Il vostro feedback",
  feedbackSubmitSuccess: "Grazie per il vostro feedback!",
  feedbackSubmitError:
    "Non è stato possibile inviare il feedback. Si prega di riprovare più tardi.",
  urlCopiedToClipboard: "L'URL è stato copiato negli appunti.",

  // translate from here
  cancel: "Annullamento",
  createDate: "Data di creazione",
  customer: "Cliente",
  description: "Descrizione",
  device: "Dispositivo",
  dropFilesHere: "Archiviare i file qui",
  dropFilesOrSelect: "Memorizzare o selezionare i file qui",
  fileTooLarge: "Il file è troppo grande. Dimensione massima del file 5 MB",
  localRequestStatusAll: "Tutti",
  localRequestStatusClosed: "Completato",
  localRequestStatusInProgress: "In corso",
  localRequestStatusOpen: "Aperto",
  localRequestStatusPending: "Eccezionale",
  messageSendError: "Impossibile inviare il messaggio",
  noteSendError: "Impossibile inviare il messaggio",
  only5FilesAllowed: "Sono consentiti solo 5 file",
  pleaseTypeYourMessage: "Inserisci il tuo messaggio",
  request: "Richiesta di assistenza",
  requestStatusAdditionProvided: "In corso",
  requestStatusCancelled: "Interrotto",
  requestStatusCompleted: "Completato",
  requestStatusCustomerAction: "In attesa di una risposta",
  requestStatusForwarded: "In corso",
  requestStatusInProgress: "In corso",
  requestStatusSolutionProvided: "Soluzione fornita",
  status: "Stato",
  uploadAttachments: "Caricare gli allegati",
  PleaseContactYourSupportTeam: "Contattare il nostro supporto applicativo",
  ErrorCode: "Codice di errore: ",
  pushNotification: "Notifiche push",
  INIT: "Iniziato",
  AWDE: "In attesa del dispositivo",
  REPA: "Riparazione completata",
  CANC: "Annullata",
  INSP: "Ispezione",
  AWCA: "In attesa dell'approvazione del cliente",
  REST: "Riparazione iniziata",
  REDE: "Riparazione negata",
  CLOS: "Processo concluso",
  REAP: "Riparazione approvata",
  REPE: "In attesa dell'approvazione del cliente",
};

const withDefaults = { ...EN, ...IT };

export default withDefaults;
