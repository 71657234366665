import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: '#0077C8',
      },
      secondary: {
        main: '#F8A700',
      },
      info: {
        main: '#EAEAEA',
        '100': '#A9ABAD',
      },
      error: {
        main: '#D61002',
      },
      background: {
        default: '#EAEAEA',
      },
    },
    typography: {
      fontFamily: ['Montserrat'].join(','),
      subtitle1: {
        fontSize: '13px',
        lineHeight: '16px',
        color: '#53585B',
      },
      subtitle2: {
        fontWeight: 600,
        color: '#53585B',
      },
      h4: {
        fontSize: '44px',
        fontWeight: 600,
      },
      h5: {
        fontSize: '36px',
        fontWeight: 500,
      },
      h6: {
        fontSize: '18px',
        color: '#53585B',
        fontWeight: 600,
      },
    },
  })
)

export const useGlobalStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
}))
