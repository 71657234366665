import { useRouter } from 'next/router'
import en from '../../../config/translations/en'
import de from '../../../config/translations/de'
import it from '../../../config/translations/it'
import fr from '../../../config/translations/fr'
import es from '../../../config/translations/es'
import Languages, { Language } from '../../../config/Languages'
import { TranslationObject } from 'src/types'

const getTranslated = (locale: Language) => {
  switch (locale) {
    case 'en':
      return en
    case 'de':
      return de
    case 'it':
      return it
    case 'fr':
      return fr
    case 'es':
      return es
    default:
      return en
  }
}

export type I18n = ReturnType<typeof getTranslated>

const useI18n = () => {
  const router = useRouter()

  const { locale, asPath } = router

  function translateObject(val: TranslationObject | string) {
    if (typeof val === 'string') {
      return val
    }
    return val[locale as Language] || val.c
  }

  function changeLanguage(locale: string) {
    if (Languages.includes(locale as Language)) {
      return router.push(asPath, asPath, { locale })
    }
    return router.push(asPath, asPath, { locale: 'en' })
  }

  return {
    locale: locale as Language,
    changeLanguage,
    i18n: getTranslated(locale as Language),
    translateObject,
  }
}

export default useI18n
