import React from 'react'
import Languages from '../../../config/Languages'
import useI18n from '../../lib/hooks/useI18n'
import HoverMenu from '../HoverMenu'

const LanguageToggle = () => {
  const { changeLanguage, i18n } = useI18n()
  return (
    <HoverMenu
      buttonLabel={i18n.language}
      menuItems={Languages.map((language) => {
        return {
          name: i18n[language],
          onClick: () => changeLanguage(language),
        }
      })}
    />
  )
}

export default LanguageToggle
